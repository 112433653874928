import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomePageComponent} from './components/home-page/home-page.component';
import {NotFoundPageComponent} from './shared/components/not-found-page/not-found-page.component';
import {SignInPageComponent} from './components/sign-in-page/sign-in-page.component';
import {SignUpPageComponent} from './components/sign-up-page/sign-up-page.component';
import {DashboardPageComponent} from './components/dashboard-page/dashboard-page.component';
import {TestsPageComponent} from './components/tests-page/tests-page.component';
import {AddTestPageComponent} from './components/add-test-page/add-test-page.component';
import {UIFooterComponent} from './components/ui-footer/ui-footer.component';
import {UIHeaderComponent} from './components/ui-header/ui-header.component';

import {AuthGuard} from './services/auth.guard';
import {CurrentUserResolver} from './resolvers/current-user.resolver';
import {ConfirmEmailPageComponent} from './components/confirm-email-page/confirm-email-page.component';
import {AboutPageComponent} from './components/about-page/about-page.component';
import {ContactUsPageComponent} from './components/contact-us-page/contact-us-page.component';
import {SetsPageComponent} from './components/sets-page/sets-page.component';
import {AddSetPageComponent} from './components/add-set-page/add-set-page.component';
import {TestsComponent} from './components/tests/tests.component';
import {Test2PageComponent} from './components/test2-page/test2-page.component';
import {ForgotPasswordPageComponent} from './components/forgot-password-page/forgot-password-page.component';

const appRoutes: Routes = [
    {
        path: 'settings',
        loadChildren: 'app/modules/settings/settings.module#SettingsModule'
    },
    {
        path: '',
        component: UIHeaderComponent,
        resolve: {
            currentUser: CurrentUserResolver
        },
        outlet: 'header'
    },
    {
        path: '',
        component: UIFooterComponent,
        outlet: 'footer'
    },
    {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
        data: {
            title: ''
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'about',
        component: AboutPageComponent,
        data: {
            title: 'about'
        }
    },
    {
        path: 'confirm-email',
        component: ConfirmEmailPageComponent,
        data: {
            title: 'confirmEmail'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'contact-us',
        component: ContactUsPageComponent,
        data: {
            title: 'contactUs'
        }
    },
    {
        path: 'sign-in',
        component: SignInPageComponent,
        data: {
            mustLoggedOut: true,
            title: 'signIn'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'sign-up',
        component: SignUpPageComponent,
        data: {
            mustLoggedOut: true,
            title: 'signUp'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        component: DashboardPageComponent,
        data: {
            title: 'dashboard'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'test',
        component: TestsComponent,
        data: {
            title: 'test'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'test2',
        component: Test2PageComponent,
        data: {
            title: 'test'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'tests',
        component: TestsPageComponent,
        data: {
            title: 'tests'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'add-test',
        component: AddTestPageComponent,
        data: {
            title: 'addTestPage'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'sets',
        component: SetsPageComponent,
        data: {
            title: 'addSetPage'
        }
    },
    {
        path: 'add-set',
        component: AddSetPageComponent,
        data: {
            title: 'addSetPage'
        }
    },
    {
        path: '**',
        component: NotFoundPageComponent,
        data: {
            title: 'notFound'
        }
    }
];

export const AppRoutingProviders: any[] = [];
export const AppRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
