import {Test} from '../interfaces/test.interface';

export const MONGOLIAN_GRAMMAR_MOCK_TEST: Test = {
    module: '',
    name: 'CeLCAR_ MPTP_ Uvsh Purev',
    instructions: 'Grammar Reading Listening Speaking',
    description: 'MONGOLIAN PTP – NOVICE LEVEL',
    sets: [
        {
            name: 'SECTION 1',
            type: 'grammar',
            duration: 30,
            totalQuestions: 40,
            instructions: 'GRAMMAR COMPREHENSION',
            description: 'Direction: This section consists of incomplete statements; each followed by four options. ' +
                'Choose the best expression to complete the sentence.',
            isRtl: false,
            groups: [
                {
                    name: 'Group Name 1',
                    text: 'Direction: This section consists of incomplete statements; each followed by four options. ' +
                        'Choose the best expression to complete the sentence.',
                    url: '',
                    type: 'text',
                    questions: [
                        {
                            difficulty: 1,
                            text: 'Таны нэрийг хэн гэдэг ___?',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'бэ?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'юу?',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'вэ?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'вэй?',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Энэ эрэгтэй хүн хэн ___?',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'бэ?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'вэ?',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'гэ?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: '-',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Энэ бал ___, харандаа ___?',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'юү, юу?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'үү, уу?',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'уу, юу?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'өө, уу?',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Та нэрээ бич __________',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'аарай',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'ээрэй',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'оорой',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'уурай',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Хичээл эхэлж байна. Хурдан ир ________',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'аарай',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'оорой',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'уурай',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'ээрэй',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Уучлаарай, орж болох уу?' +
                                'B: За, ор_______',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'ээрэй',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'оорой',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'уурай',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'харай',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Уучлаарай, Mary & Marth дэлгүүр хаана _________ вэ?' +
                                'B: Их дэлгүүрийн хажууд _______.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'байна, байгаа',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'байдаг, байдаг',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'байна, байдаг',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'вайдаг, байна',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Миний дэвтэр чамд байгаа юу?' +
                                'B: ___________',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'байна',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'байдаг',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'байгаа',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'байсан',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Амина, Ганаа, Сараа гурав юу хийж байна вэ?' +
                                'B: ______ ангид хичээлээ хийж байна.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'ээр',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'тэд',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'гурав',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'бид',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Чи ямар хэл сурдаг вэ?' +
                                'B: ____ Монгол хэл сурдаг.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'тэд',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'би',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'тэр эмэгтэй',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'чи',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Би одоо гэрийн даалгавараа _____ байна.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'хийх',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хийж',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'хйич',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хийнэ',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Чи гэрийн даалгавараа хийж байна уу? \n' +
                                'B: Үгүй, би гэрийн даалгавараа ___________ байна.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'хийхгүй',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хийдэггүй',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'хийгээгүй',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хийсэнгүй',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Энэ таны машин уу?' +
                                'B: Тийм, энэ ___________ машин.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'чиний',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'тэдний',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'миний',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'бидний',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'Part B',
                    text: 'Direction: This section consists of error identification; mark the choice that matches' +
                        ' the incorrect underlined word or phrase.',
                    url: '',
                    type: 'text',
                    questions: [
                        {
                            difficulty: 1,
                            text: '<span style="text-decoration: underline;">Бид</span> англи ' +
                                '<span style="text-decoration: underline;">хэлний</span>' +
                                ' ном <span style="text-decoration: underline;">тэнд' +
                                '</span> <span style="text-decoration: underline;">байна.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b?',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd?',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: '<span style="text-decoration: underline;">Өнөөдөр</span>' +
                                '<span style="text-decoration: underline;">манайхны</span>' +
                                '<span style="text-decoration: underline;">хичээлийн</span> ' +
                                '<span style="text-decoration: underline;">эхний</span> өдөр сонирхолтой' +
                                '<span style="text-decoration: underline;">байлаа.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b?',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c?',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Миний монгол <span style="text-decoration: underline;">хэлний</span>' +
                                '<span style="text-decoration: underline;">багшийн</span>' +
                                '<span style="text-decoration: underline;">нэрийг</span> Болд ' +
                                '<span style="text-decoration: underline;">гуай</span> ' +
                                '<span style="text-decoration: underline;">гэдэг.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: '<span style="text-decoration: underline;">Болдын</span>' +
                                '<span style="text-decoration: underline;">нарын</span>' +
                                '<span style="text-decoration: underline;">дүүгийн</span> ' +
                                '<span style="text-decoration: underline;">нэрийг</span> Ганбат гэдэг.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Таны машин <span style="text-decoration: underline;">хоёр</span>' +
                                '<span style="text-decoration: underline;">автобуcны</span>' +
                                '<span style="text-decoration: underline;">хооронд</span> ' +
                                '<span style="text-decoration: underline;">голд</span> байна.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Төрсөн <span style="text-decoration: underline;">өдрийн</span>' +
                                '<span style="text-decoration: underline;">хүндэтгэлийн</span> бялуу ' +
                                '<span style="text-decoration: underline;">хөргөгчөн</span> ' +
                                '<span style="text-decoration: underline;">дотор</span> байна.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'А: Кино театрт <span style="text-decoration: underline;">зааланд</span> кино' +
                                '<span style="text-decoration: underline;">үзсэн үү?</span> ' +
                                '<p>B:<span style="text-decoration: underline;">Үгүй,</span> кино ' +
                                '<span style="text-decoration: underline;">үзэхгүй.</span></p>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: '<span style="text-decoration: underline;">Уржигдар</span> гэр ' +
                                '<span style="text-decoration: underline;">бүлээрэй</span> хамт ' +
                                '<span style="text-decoration: underline;">бөөгнөрөн</span> оройн хоол' +
                                '<span style="text-decoration: underline;">идсэн.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Улаанбаатар зочид <span style="text-decoration: underline;">буудлын</span> ' +
                                '<span style="text-decoration: underline;">хажууд</span> ' +
                                '<span style="text-decoration: underline;">багшийн</span>' +
                                '<span style="text-decoration: underline;">нэртэй</span> сургууль байдаг.',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: '<span style="text-decoration: underline;">Өчигдөрийн</span> ' +
                                '<span style="text-decoration: underline;">оройн</span> хоол ' +
                                '<span style="text-decoration: underline;">үлэмж</span> амттай ' +
                                '<span style="text-decoration: underline;">байсан.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Бид <span style="text-decoration: underline;">хамтдаа</span> ' +
                                '<span style="text-decoration: underline;">өдрийн</span> хоол  ' +
                                '<span style="text-decoration: underline;">идэж</span> ' +
                                '<span style="text-decoration: underline;">байна.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Таны <span style="text-decoration: underline;">дэргэд</span> цүнх ' +
                                '<span style="text-decoration: underline;">сандлын</span> ' +
                                '<span style="text-decoration: underline;">доор</span> ' +
                                '<span style="text-decoration: underline;">байна.</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 1,
                            text: 'Туяа <span style="text-decoration: underline;">ямар</span> ' +
                                '<span style="text-decoration: underline;">хэнд</span> ном ' +
                                '<span style="text-decoration: underline;">өгсөн</span> ' +
                                '<span style="text-decoration: underline;">бэ?</span>',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'a',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'b',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'c',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'd',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        }
                    ]
                }
            ]
        },


        {
            name: 'Part C',
            type: 'grammar',
            duration: 10,
            totalQuestions: 15,
            instructions: 'Direction: read the following texts and complete them choosing the most appropriate option.',
            description: 'GRAMMAR COMPREHENSION',
            isRtl: false,
            groups: [
                {
                    name: 'Fill in the gaps',
                    text: 'А. Улаанбаатар',
                    url: '/assets/images/grammar/grammar_1.jpg',
                    type: 'image',
                    questions: [
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'дөхөм',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'ойролцоо',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'шахуу',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'гаруй',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'гэдэг',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'гэж',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'гэлээ',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'гэнэ',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'дээр',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хажууд',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'доор',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'дэргэд',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'бас',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'бөгөөд',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'болон',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'гадна',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'ресторанд',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'ресторануудын',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'ресторан',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'рестораны',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'байшинд',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'барилгад',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'гэрт',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'майханд',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'яагаад гэвэл',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'тийм учраас',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'тиймээс',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'үүнээс',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'Fill in the gaps',
                    text: 'B. Гадаад хүнд өгөх зөвлөмж',
                    url: '/assets/images/grammar/grammar_1.jpg',
                    type: 'image',
                    questions: [
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'уламжлал',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'заншил',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'соёл',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'жудаг',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'хүйтэн',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'тэсрэг',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'тэс',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'өөр',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'мөч',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'үе',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'цаг',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хором',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'хүртэл',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хүрэх',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'тултал',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'дөхөх',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'үйчлэх',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'үйлчлүүлэх',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'үйлчилсэн',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'үйлчлүүлэгч',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'явах',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'явангуут',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'явахдаа',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'явсан',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        },
                        {
                            difficulty: 5,
                            text: '',
                            url: '',
                            type: 'text',
                            answers: [
                                {
                                    answer: 'хаана',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хаанаас ч',
                                    type: 'text',
                                    isCorrect: true
                                },
                                {
                                    answer: 'хаашаа',
                                    type: 'text',
                                    isCorrect: false
                                },
                                {
                                    answer: 'хаана ч',
                                    type: 'text',
                                    isCorrect: false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
