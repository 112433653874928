import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../modules/config/config.service';
import {of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';

export interface Role {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'sign-up-page',
    templateUrl: 'sign-up-page.component.html',
    styleUrls: ['sign-up-page.component.scss']
})

export class SignUpPageComponent implements OnDestroy, OnInit {
    apiUrl: string;
    data: any = {};
    successMessage = '';
    errorMessage = '';
    hidePassword = true;
    hideConfirmPassword = true;
    signUpForm: FormGroup;
    signUpProcessInProgress: boolean;

    /*newUser = {
        firstName : '',
        lastName : '',
        email : '',
        password : '',
        password2 : '',
        role : '',
        isSubscribed : 0
    };

    roles: Role[] = [
        {value: 'student-0', viewValue: 'Student'},
        {value: 'instructor-1', viewValue: 'Instructor'},
        {value: 'administrator-2', viewValue: 'Administrator'}
    ];*/

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private formBuilder: FormBuilder,
                private router: Router) {
        this.apiUrl = this.configService.apiUrl;
    }

    onClose(): void {
        this.errorMessage = null;
        this.successMessage = null;
        this.signUpProcessInProgress = false;
    }

    oauth(provider: string): void {
        window.open(this.apiUrl + '/oauth/' + provider, '_self');
    }

    onSubmitSignUpForm(): void {
        if (this.signUpForm.valid) {
            const data: any = {};
            data.firstName = this.signUpForm.controls.firstName.value;
            data.lastName = this.signUpForm.controls.lastName.value;
            data.email = this.signUpForm.controls.email.value;
            data.username = this.signUpForm.controls.email.value;
            data.password = this.signUpForm.controls.password.value;
            this.authService.signUp(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((message: string): void => {
                    this.signUpProcessInProgress = true;
                    this.successMessage = 'We have sent you an email with a link to activate your account. Please check.';
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
            this.signUpProcessInProgress = true;
        }
        this.errorMessage = null;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.initSignUpForm();
    }

    private initSignUpForm(): void {
        this.signUpForm = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
        }, {validator: this.checkPasswords });
    }

    private checkPasswords(formGroup: FormGroup): any {
        const password = formGroup.controls.password.value;
        const confirmPassword = formGroup.controls.confirmPassword.value;

        if (password !== confirmPassword) {
            formGroup.get('confirmPassword').setErrors({matchPassword: true});
        }
    }

    /*onSignUp() {
        if (this.newUser.password === this.newUser.password2) {
            this.isPasswdCorrect =  this.checkPassword(this.newUser.password);
            if (this.isPasswdCorrect) {
                console.log('Password must be minimum 8 characters');
            } else {
                if (this.newUser.isSubscribed) {
                    this.newUser.isSubscribed = 1;
                } else {
                    this.newUser.isSubscribed = 0;
                }

                console.log(this.newUser);
                this.authService.create(this.newUser)
                    .subscribe((message: string): void => {
                        console.log(message);
                    }, (err: any): void => {
                        this.configService.errorHandler(err);
                    });
            }
        } else {
            console.log('Provided passwords must match!');
        }

    }

    checkPassword(p: any) {
        if (p.value.match(this.passwd)) {
            return true;
        } else {
            return false;
        }
    }

    ngOnInit(): void {
    }*/
}
