import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, timer} from 'rxjs';

import {ConfigService} from '../../../modules/config/config.service';
import {TestGroup, TestStatus} from '../../interfaces/test.interface';
import {TestPlayerService} from '../../../services/test-player.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'test-player-group',
    templateUrl: 'test-player-group.component.html',
    styleUrls: ['test-player-group.component.scss']
})
export class TestPlayerGroupComponent implements OnDestroy, OnInit {
    @Input() data: TestGroup;
    @Input() i: number;
    @ViewChild ('groupAudio', {static: false}) groupAudio: ElementRef;

    currentSetName: string;
    currentTestName: string;
    group: TestGroup;

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService,
                private testPlayerService: TestPlayerService) {
    }

    onFinish(): void {
    }

    onNext(): void {
        this.testPlayerService.nextGroup();
        console.log(this.data.url);
        console.log(this.data.name);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.group = this.data;

        this.testPlayerService.currentTestStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((testStatus: TestStatus): void => {
                this.currentTestName = testStatus.name;
                this.currentSetName = testStatus.currentSetName;
                timer(0)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((): void => {
                        this.group = this.data;
                        if (this.data.type === 'audio') {
                            this.groupAudio.nativeElement.src = this.group.url;
                        }
                    });
            });
    }
}
