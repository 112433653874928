import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {catchError, map} from 'rxjs/internal/operators';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs/index';

import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {TtUser} from '../shared/interfaces/user.interface';
import {UserService} from '../services/user.service';

@Injectable()
export class CurrentUserResolver implements Resolve<TtUser> {

    constructor(private userService: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TtUser> {
        return this.userService.retrieveCurrentUser()
            .pipe(
                map((user: any): any => {
                    return user;
                }),
                catchError((err: TtErrorResponse): Observable<TtUser> => {
                    return of(null);
                })
            );
    }
}
