import {NgModule} from '@angular/core';

import {ConfigService} from './config.service';

@NgModule({})
export class ConfigModule {
    static forRoot() {
        return {
            ngModule: ConfigModule,
            providers: [ConfigService]
        };
    }
}
