import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';

import {TtDialog} from '../../interfaces/dialog.interface';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnDestroy, OnInit {

    cancelButtonText: string;
    confirmButtonText: string;
    dialogMessage: string;
    dialogTitle: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: TtDialog,
                public dialogRef: MatDialogRef<ConfirmDialogComponent>,
                private translateService: TranslateService) {
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onTranslationsLoaded(translations: any): void {
        this.dialogMessage = this.data.dialogMessage || translations.areYouSure;
        this.dialogTitle = this.data.dialogTitle;
        if (this.data.dialogActionBar) {
            this.cancelButtonText = this.data.dialogActionBar.cancelButtonText || translations.cancel;
            this.confirmButtonText = this.data.dialogActionBar.confirmButtonText || translations.yes;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.translateService.get(['confirmDialog'])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((translations: any): void => {
                this.onTranslationsLoaded(translations.confirmDialog);
            }, (err: Error): void => {
                console.log(err);
            });
    }
}
