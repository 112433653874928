import {Subject} from 'rxjs';

export class CommonUtil {

    static cloneObject(obj: object): any {
        const newObj = {};
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                newObj[prop] = obj[prop];
            }
        }
        return newObj;
    }

    static getImageDimensions(file: any): Promise<any> {
        return new Promise((resolve?: Function, reject?: Function): void => {
            const i = new Image();
            i.onload = (): void => {
                resolve({width: i.width, height: i.height});
            };
            i.src = file;
        });
    }

    static getImageDimensionsFromUrl(photoUrl: string): any {
        photoUrl = photoUrl.replace('-xazzb-', '-dim-div-');
        if (photoUrl.indexOf('-dim-div-') > -1) {
            photoUrl = photoUrl.split('-dim-div-')[1];
            const dim = photoUrl.split('.')[0].split('x');
            return {
                width: Number(dim[0]),
                height: Number(dim[1])
            };
        } else {
            return {
                width: 600,
                height: 600
            };
        }
    }

    static makeEmbed(youTubeLink: string): string {
        if (youTubeLink) {
            // Remove params
            youTubeLink = this.removeUrlParam(youTubeLink, 't');
            youTubeLink = this.removeUrlParam(youTubeLink, 'feature');
            if (youTubeLink.indexOf('https://youtu.be/') > -1) {
                youTubeLink = youTubeLink.replace('https://youtu.be/', '').split('?t=')[0];
                return 'https://www.youtube.com/embed/' + youTubeLink;
            }
            if (youTubeLink.split('v=')[1]) {
                return 'https://www.youtube.com/embed/' + youTubeLink.split('v=')[1];
            } else if (youTubeLink.indexOf('embed') > 0) {
                return youTubeLink;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    static paramsToUrl(params: object): string {
        let url = '';
        for (const key in params) {
            if (params[key] && params.hasOwnProperty(key)) {
                url += key + '=' + params[key] + '&';
            }
        }
        return url.replace(/&$/ig, '');
    }

    public static removeUrlParam(url, parameter) {
        const urlParts = url.split('?');
        if (urlParts.length >= 2) {
            const prefix = encodeURIComponent(parameter) + '=';
            const pars = urlParts[1].split(/[&;]/g);
            for (let i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    public static removeObjectsFromArrayById(arr, ids): Array<any> {
        arr = arr || [];
        ids = ids || [];
        if (!Array.isArray(ids)) {
            ids = ids.split(',');
        }

        const res = [];
        for (let i = 0; i < arr.length; i++) {
            const index = ids.indexOf(arr[i]._id);
            if (index === -1) {
                res.push(arr[i]);
            }
        }
        return res;
    }

    private resizeDataUrl(dataUrl: string, wantedMaxWidth: number, wantedMaxHeight: number): Subject<any> {
        const subject = new Subject<any>();
        const img = document.createElement('img');
        img.onload = (): void => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (img.width > img.height) {
                wantedMaxHeight = img.height * (wantedMaxWidth / img.width);
            } else {
                wantedMaxWidth = img.width * (wantedMaxHeight / img.height);
            }
            canvas.width = wantedMaxWidth;
            canvas.height = wantedMaxHeight;
            ctx.drawImage(img, 0, 0, wantedMaxWidth, wantedMaxHeight);
            subject.next({
                dataUrl: canvas.toDataURL(),
                width: wantedMaxWidth.toFixed(0),
                height: wantedMaxHeight.toFixed(0)
            });
            subject.complete();
        };
        img.src = dataUrl;
        return subject;
    }
}
