import {catchError, map} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs/index';

import {StorageService} from './storage.service';
import {ConfigService} from '../modules/config/config.service';
import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {TtUser} from '../shared/interfaces/user.interface';

@Injectable()
export class UserService {

    currentUser$ = new ReplaySubject(1);

    private _currentUser: any;
    private apiUrl: string;

    constructor(private configService: ConfigService,
                private storageService: StorageService,
                private http: HttpClient) {
        // this.apiUrl = this.configService.apiUrl + '/users';
        this.apiUrl = this.configService.apiUrl;
    }

    set currentUser(user: any) {
        this._currentUser = user;
        this.currentUser$.next(this._currentUser);
    }

    get currentUser(): any {
        return this._currentUser;
    }

    retrieveById(id: string): Observable<any | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/read_one.php?id=' + id)
            .pipe(
                map((res: any): any => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    retrieveCurrentUser(): Observable<any | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/validate_token.php')
            .pipe(
                map((res: any): any => {
                    this.currentUser = res;
                    return this.currentUser;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    search(params?: string): Observable<TtUser[] | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/search' + (params ? '?' + params : ''))
            .pipe(
                map((res: TtUser[]): TtUser[] => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    verifyEmail(confirmationCode: string): Observable<string | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/verify_user.php?confirmation=' + confirmationCode)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    update(data ): Observable<TtUser | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_user.php', data)
            .pipe(
                map((res: { message: string; user: TtUser }): any => {
                    // console.log(res);
                    this.currentUser = res.user;
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    updateUserProfile(data ): Observable<TtUser | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_user_profile.php', data)
            .pipe(
                map((res: { message: string; user: TtUser }): any => {
                    // console.log(res);
                    this.currentUser = res.user;
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    /*
    signIn(signInData: TtUser): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/login.php', signInData)
            .pipe(
                map((res: { message: string; user: TtUser, token: any }): any => {
                    console.log('Here is user info: ' + res.user);
                    this.storageService.setItem('token', res.token);
                    this.currentUser = res.user;
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    // console.log(err);
                    return throwError(err);
                })
            );
    }
    */


    updatePassword(data: any): Observable<string | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_password.php', data)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }
    updateAccess(data: any): Observable<string | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_user_access.php', data)
            .pipe(
                map((res: { message: string; user: TtUser }): any => {
                    // console.log(res);
                    this.currentUser = res.user;
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    delete(): Observable<TtErrorResponse> {
        return this.http.delete(this.apiUrl)
            .pipe(
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

}
