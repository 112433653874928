import {Injectable, OnDestroy} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {

    private ngUnsubscribe = new Subject<void>();

    constructor(private translateService: TranslateService) {
        super();

        this.translateService.onLangChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: LangChangeEvent): void => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translateService.get(['matPaginator'])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((translations: any): void => {
                this.firstPageLabel = translations.matPaginator['firstPageLabel'];
                this.itemsPerPageLabel = translations.matPaginator['itemsPerPageLabel'];
                this.lastPageLabel = translations.matPaginator['lastPageLabel'];
                this.nextPageLabel = translations.matPaginator['nextPageLabel'];
                this.previousPageLabel = translations.matPaginator['previousPageLabel'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
