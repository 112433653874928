import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

import {ConfigService} from '../../../modules/config/config.service';
import {TestQuestion} from '../../interfaces/test.interface';
import {MatRadioChange} from '@angular/material';

@Component({
    selector: 'test-player-question',
    templateUrl: 'test-player-question.component.html',
    styleUrls: ['test-player-question.component.scss']
})
export class TestPlayerQuestionComponent implements OnInit {

    @Input() data: TestQuestion;
    @Input() i: number;

    question: TestQuestion;
    selectedAnswer: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService) {
    }

    onAnswerSelected(event: MatRadioChange): void {
        console.log(event);
        console.log(this.selectedAnswer);
    }

    onSubmit(): void {
    }

    ngOnInit(): void {
        this.question = this.data;
    }
}
