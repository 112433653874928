import {Component, Input, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '../../../modules/config/config.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'not-found-page',
    templateUrl: 'not-found-page.component.html',
    styleUrls: ['not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

    @Input() data: any;

    isSubPage: boolean;

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService) {
    }

    ngOnInit(): void {
        this.configService.mainRouterOutletData$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any): void => {
                this.isSubPage = data.isSubPage;
            });

        if (this.data && this.data.hasOwnProperty('isSubPage')) {
            this.isSubPage = this.data.isSubPage;
        }
    }
}
