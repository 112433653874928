import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../modules/config/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TestQuestion, TestSet} from '../../shared/interfaces/test.interface';
import {takeUntil} from 'rxjs/internal/operators';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {UserService} from '../../services/user.service';
import {SetService} from '../../services/set.service';

@Component({
    selector: 'app-add-set-page',
    templateUrl: 'add-set-page.component.html',
    styleUrls: ['add-set-page.component.scss']
})
export class AddSetPageComponent implements OnInit {

    test: TestSet = {
        groups: [
            {
                type: 'none',
                name: 'Group',
                questions: []
            }
        ]
    };

    newQuestion: TestQuestion = {
        type: 'text',
        text: 'text',
        url: 'text',
        answers: []
    };
    multipleChoiceForm: FormGroup;
    addSetProcessInProgress: boolean;
    testTypeOptions: any[] = [
        {
            label: 'Add Audio (.mp3)',
            value: 'audio'
        },
        {
            label: 'Add Video (.mp4)',
            value: 'video'
        },
        {
            label: 'Add Image (.png/jpeg)',
            value: 'image'
        },
        {
            label: 'Add Passage (text)',
            value: 'text'
        },
        {
            label: 'None',
            value: 'none'
        },
    ];
    selectedIndex = 0;
    index = '1';
    currentUser: any;
    successMessage = '';
    errorMessage = '';
    staticServerUrl: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private formBuilder: FormBuilder,
                private configService: ConfigService,
                private userService: UserService,
                private setService: SetService,
                private route: ActivatedRoute,
                private router: Router) {
        this.multipleChoiceForm = formBuilder.group({
            type: 'text',
            text: 'text',
            url: 'text',
            answers: []
        });
    }

    addTab(event: any): void {
        if (event.index === this.test.groups.length) {
            this.test.groups.push({
                name: 'Group',
                questions: []
            });
            event.tab.isActive = false;
        }
    }

    removeTab(index: number) {
        this.test.groups.splice(index, 1);
        this.selectedIndex = this.test.groups.length - 1;
        console.log('selected index' + ' ' + this.selectedIndex);
        console.log('tab length' + ' ' + this.test.groups.length);
    }

    onSelect(event: Event): void {
        const file = event.target['files'][0];
        this.newQuestion.type = file.type.split('/')[0];
        this.newQuestion.text = file.name;
        this.newQuestion.url = file;
    }

    onSubmit(groupIndex: number): void {
        this.test.groups[groupIndex].questions.push(this.newQuestion);
        this.newQuestion = {
            type: 'text',
            answers: []
        };
    }

    onSubmitMultipleChoiceForm(): void {
        if (this.multipleChoiceForm.valid) {
            const data: any = {};
            data.userId = this.currentUser.id;
            data.userRole = this.currentUser.role;
            data.title = this.multipleChoiceForm.controls.title.value; // to be shown on the opening slide-page
            data.description = this.multipleChoiceForm.controls.description.value; // to be shown on the opening slide-page
            data.instructions = this.multipleChoiceForm.controls.instructions.value; // to be shown on the opening slide-page
            data.type = this.multipleChoiceForm.controls.type.value; // to be shown on an opening slide-page as in icon
            data.langDirection = this.multipleChoiceForm.controls.langDirection.value; // to identify if the test is for Arabic script based language or Hebrew
            data.duration = this.multipleChoiceForm.controls.duration.value; // time indicated for completion of this set
            data.questions = [
                {
                    group_order: 1,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Таны нэрийг хэн гэдэг ___?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'бэ?',
                        a2_text: 'юу?',
                        a3_text: 'вэ?',
                        a4_text: 'вэй?',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'вэ?'
                    }]
                },
                {
                    group_order: 2,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Энэ эрэгтэй хүн хэн ___?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'бэ?',
                        a2_text: 'вэ?',
                        a3_text: 'гэ?',
                        a4_text: '-',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'бэ?'
                    }]
                },
                {
                    group_order: 3,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Энэ бал ___, харандаа ___?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'юү, юу?',
                        a2_text: 'үү, уу?',
                        a3_text: 'уу, юу?',
                        a4_text: 'өө, уу?',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'уу, юу?'
                    }]
                },
                {
                    group_order: 4,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Та нэрээ бич __________',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'аарай',
                        a2_text: 'ээрэй',
                        a3_text: 'оорой',
                        a4_text: 'уурай',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'ээрэй'
                    }]
                },
                {
                    group_order: 5,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Хичээл эхэлж байна. Хурдан ир ________',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'аарай',
                        a2_text: 'оорой',
                        a3_text: 'уурай',
                        a4_text: 'ээрэй',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'ээрэй'
                    }]
                },
                {
                    group_order: 6,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Уучлаарай, орж болох уу?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: За, ор_______',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'ээрэй',
                            a2_text: 'оорой',
                            a3_text: 'уурай',
                            a4_text: 'харай',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'оорой'
                        }]
                },
                {
                    group_order: 7,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Уучлаарай, Mary & Marth дэлгүүр хаана _________ вэ?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: Их дэлгүүрийн хажууд _______.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'байна, байгаа',
                            a2_text: 'байдаг, байдаг',
                            a3_text: 'байна, байдаг',
                            a4_text: 'вайдаг, байна',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'байдаг, байдаг'
                        }]
                },
                {
                    group_order: 8,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Миний дэвтэр чамд байгаа юу?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: ___________',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'байна',
                            a2_text: 'байдаг',
                            a3_text: 'байгаа',
                            a4_text: 'байсан',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'байгаа'
                        }]
                },
                {
                    group_order: 9,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Амина, Ганаа, Сараа гурав юу хийж байна вэ?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: ______ ангид хичээлээ хийж байна.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'ээр',
                            a2_text: 'тэд',
                            a3_text: 'гурав',
                            a4_text: 'бид',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'тэд'
                        }]
                },
                {
                    group_order: 10,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [
                        {
                            q_difficulty: 3,
                            q_text: 'А: Чи ямар хэл сурдаг вэ?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '',
                            a2_text: '',
                            a3_text: '',
                            a4_text: '',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'B: ____ Монгол хэл сурдаг.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'тэд',
                            a2_text: 'би',
                            a3_text: 'тэр эмэгтэй',
                            a4_text: 'чи',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'би'
                        }]
                },
                {
                    group_order: 11,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Би одоо гэрийн даалгавараа _____ байна.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'хийх',
                        a2_text: 'хийж',
                        a3_text: 'хйич',
                        a4_text: 'хийнэ',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'хийж'
                    }]
                },
                {
                    group_order: 12,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Чи гэрийн даалгавараа хийж байна уу?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: Үгүй, би гэрийн даалгавараа ___________ байна.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'хийхгүй',
                            a2_text: 'хийдэггүй',
                            a3_text: 'хийгээгүй',
                            a4_text: 'хийсэнгүй',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'хийгээгүй'
                        }]
                },
                {
                    group_order: 13,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [
                        {
                            q_difficulty: 3,
                            q_text: 'А: Энэ таны машин уу?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '',
                            a2_text: '',
                            a3_text: '',
                            a4_text: '',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'B: Тийм, энэ ___________ машин.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'чиний',
                            a2_text: 'тэдний',
                            a3_text: 'миний',
                            a4_text: 'бидний',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'миний'
                        }
                    ]
                },
                {
                    group_order: 14,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '<u>Бид</u> англи <u>хэлний</u> ном <u>тэнд</u> <u>байна.</u>',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'a'
                    }
                    ]
                },
                {
                    group_order: 15,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [
                        {
                            q_difficulty: 3,
                            q_text: '<u>Өнөөдөр</u> <u>манайхны</u> <u>хичээлийн</u> <u>эхний</u> өдөр сонирхолтой <u>байлаа.</u>',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'a',
                            a2_text: 'b',
                            a3_text: 'c',
                            a4_text: 'd',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'b'
                        }
                    ]
                },
                {
                    group_order: 16,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Миний монгол <u>хэлний</u> <u>багшийн</u> <u>нэрийг</u> Болд <u>гуай</u> <u>гэдэг.</u>',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'c'
                    }]
                },
                {
                    group_order: 17,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '<u>Болдын</u> <u>нарын</u> <u>дүүгийн</u> <u>нэрийг</u> Ганбат гэдэг.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'b'
                    }]
                },
                {
                    group_order: 18,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Таны машин <u>хоёр</u> <u>автобуcны</u> <u>хооронд</u> <u>голд</u> байна.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'c'
                    }]
                },
                {
                    group_order: 19,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Төрсөн <u>өдрийн</u> <u>хүндэтгэлийн</u> бялуу <u>хөргөгчөн</u> <u>дотор</u> байна.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'b'
                    }]
                },
                {
                    group_order: 20,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А: Кино театрт <u>зааланд</u> кино <u>үзсэн үү?</u>',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'a'
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'B: <u>Үгүй</u>, кино <u>үзэхгүй</u>.',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'c',
                            a2_text: 'd',
                            a3_text: '',
                            a4_text: '',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 21,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '<u>Уржигдар</u> гэр <u>бүлээрэй</u> хамт <u>бөөгнөрөн</u> оройн хоол <u>идсэн</u>.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'c'
                    }]
                },
                {
                    group_order: 22,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Улаанбаатар зочид <u>буудлын</u> <u>хажууд</u> <u>багшийн</u> <u>нэртэй</u> сургууль байдаг.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'd'
                    }]
                },
                {
                    group_order: 23,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '<u>Өчигдөрийн</u> <u>оройн</u> хоол <u>үлэмж</u> амттай <u>байсан</u>',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'c'
                    }]
                },
                {
                    group_order: 24,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Бид <u>хамтдаа</u> <u>өдрийн</u> хоол <u>идэж</u> байна. &mdash;',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'd'
                    }]
                },
                {
                    group_order: 25,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Таны <u>дэргэд</u> цүнх <u>сандлын</u> <u>доор</u> <u>байна</u>.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'a'
                    }]
                },
                {
                    group_order: 26,
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Туяа <u>ямар</u> <u>хэнд</u> ном <u>өгсөн</u> <u>бэ</u>?',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: 'a',
                        a2_text: 'b',
                        a3_text: 'c',
                        a4_text: 'd',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: 'a'
                    }]
                },
                {
                    group_order: 'Fill in the gaps',
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'А. Улаанбаатар  \n' +
                            'Улаанбаатар олон зууны түүхтэй хот. Нэгэн цагт азийн цагаан дагина хэмээн дуудагддаг байсан. Монгол улсын нийслэл Улаанбаатар хотод нэг сая ________(27) хүн амьдардаг. Улаанбаатарын төв талбайг Чингисийн талбай _________ (28). Чингисийн талбайн дунд Сүхбаатарын хөшөө байдаг. Чингисийн талбайн ________ (29) Монгол улсын Засгийн газрын ордон байдаг. Засгийн газрын ордоны өмнө Чингис хааны хөшөө байдаг. Чингисийн талбай их том. Улаанбаатарт олон их дээд сургууль, зоогийн газар, зочид буудал, театр, музей __________ (30) хөшөө байдаг. Улаанбаатарын _______________ (31) хоол амттай мөн хямд. Улаанбаатарчууд орон сууцанд, бас монгол _________ (32) амьдардаг. Тэнд олон орон сууцны хорооло \n барьж байна. Олон хүмүүс шинэ байранд орж байна. Монголд орон сууц маш үнэтэй. Хоёр өрөө байр 50 – 70 сая төгрөгийн үнэтэй. Гэр хороолол хотын захад байдаг. Гэр хороололд халуун ус байхгүй. Улаанбаатарын нэг тулгамдсан асуудал бол утаа. ________________ (33) Улаанбаатар дөрвөн уулын дунд байдаг. Бас олон машинтай мөн гэр хороололын айлууд өвлийн улиралд гал түлдэг.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: '27',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'дөхөм',
                            a2_text: 'ойролцоо',
                            a3_text: 'шахуу',
                            a4_text: 'гаруй',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'гаруй'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '28',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'гэдэг',
                            a2_text: 'гэж',
                            a3_text: 'гэлээ',
                            a4_text: 'гэнэ',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'гэдэг'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '29',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'дээр',
                            a2_text: 'хажууд',
                            a3_text: 'доор',
                            a4_text: 'дэргэд',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'хажууд'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '30',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'бас',
                            a2_text: 'бөгөөд',
                            a3_text: 'болон',
                            a4_text: 'гадна',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'бас'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '31',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'ресторанд',
                            a2_text: 'ресторануудын',
                            a3_text: 'ресторан',
                            a4_text: 'рестораны',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'ресторануудын'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '32',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'байшинд',
                            a2_text: 'барилгад',
                            a3_text: 'гэрт',
                            a4_text: 'майханд',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'гэрт'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '33',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'яагаад гэвэл',
                            a2_text: 'тийм учраас',
                            a3_text: 'тиймээс',
                            a4_text: 'үүнээс',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'яагаад гэвэл'
                        }]
                },
                {
                    group_order: 'B. Гадаад хүнд өгөх зөвлөмж',
                    group_type: 'grammar',
                    group_url: '',
                    group_iframe: 'grammar',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Хэрэв та монгол руу удаан хугацаагаар ажиллаж амьдрахаар явж байгаа бол монгол хэлийг сайн сурах хэрэгтэй. Мөн түүнчлэн та монгол ёс ___________ (34) сайн мэдэх хэрэгтэй. Монгол эрс ________ (35) цаг ууртай. Заримдаа 5 болон 9 сард цас орох _________ (36) байдаг. Монголын өвөл их хүйтэн. Нэгдүгээр сард өдөртөө 20 – 25 хэм, шөнөдөө 20 – 30 хэм ___________ (37) хүйтэн байдаг. Тийм учраас дулаан хувцас авчрах хэрэгтэй. Улаанбаатарт хувцас болон хоол хүнс хямд бөгөөд чанартай. Монголын үхрийн болон хонины мах амттай. Олон гадаад хүмүүс меркури хүнсний захаар ____________ (38) дуртай байдаг. Тэндээс та хайсан хүнсний бараагаа худалдаж авч болно. Та шөнө орой, олон нийт цугласан зах болон худалдааны газруудаар ____________ (39) сэрэмжтэй байх хэрэгтэй. Монголд бензин үнэтэй. Бас өвлийн улиралд машины дулаан гараж үнэтэй байдаг. Тийм учраас та таксигаар явж болно. Такси 1 км нь 800 төгрөг. Гудамжинд такси барих амархан. Мөн та автобусанд сууж болно. Монголчууд их элэгсэг, зочломтгой ард түмэн. Тэд танд хэзээд ч, ____________ туслахад бэлэн.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: '34',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'уламжлал',
                            a2_text: 'заншил',
                            a3_text: 'соёл',
                            a4_text: 'жудаг',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'заншил'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '35',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'хүйтэн',
                            a2_text: 'тэсрэг',
                            a3_text: 'тэс',
                            a4_text: 'өөр',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'тэс'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '36',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'мөч',
                            a2_text: 'үе',
                            a3_text: 'цаг',
                            a4_text: 'хором',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'үе'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '37',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'хүртэл',
                            a2_text: 'хүрэх',
                            a3_text: 'тултал',
                            a4_text: 'дөхөх',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'хүртэл'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '38',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'үйчлэх',
                            a2_text: 'үйлчлүүлэх',
                            a3_text: 'үйлчилсэн',
                            a4_text: 'үйлчлүүлэгч',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'үйлчлүүлэх'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '39',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'явах',
                            a2_text: 'явангуут',
                            a3_text: 'явахдаа',
                            a4_text: 'явсан',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'явахдаа'
                        },
                        {
                            q_difficulty: 5,
                            q_text: '40',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'хаана',
                            a2_text: 'хаанаас ч',
                            a3_text: 'хаашаа',
                            a4_text: 'хаана ч',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'хаана ч'
                        }]
                },
                {
                    group_order: 1,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Дүүрэг: Баянгол дүүрэг                      \n' +
                            'Байршил: 3-р хороолол \n' +
                            'Нийт талбайн хэмжээ: 32м2\n' +
                            'Ашиглалтанд орсон он:     1987 \n' +
                            'Хэдэн давхар барилга        9 давхар \n' +
                            'Хэдэн цонхтой                    2 цонхтой \n' +
                            'Цонхны төрөл                     Вакум \n' +
                            'Хаалганы төрөл                  Бүргэд хаалга \n' +
                            'Шал төрөл                           Паркет \n' +
                            'Гараж                                   Байхгүй',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is being advertised?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'apartment',
                            a2_text: 'car',
                            a3_text: 'laptop',
                            a4_text: 'supermarket',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'apartment'
                        }]
                },
                {
                    group_order: 2 - 3,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Засгийн газрын ард 3 яамны хажууд (Сангийн, Зам тээвэр, Аялал жуулчлал) 5 давхар саарал байрны 3 давхарт 4 цонхтой 3 өрөө байр хямд зарна. Том болконтой, камержуулсан, маш дулаан. Мөн гаражтай.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'According to the text, where is the apartment located?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'downtown',
                            a2_text: 'uptown',
                            a3_text: 'behind the government palace',
                            a4_text: 'next to the post',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: 'downtown'
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'According to the text, how many windows does the apartment have?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '3 windows',
                            a2_text: '1 window',
                            a3_text: '4 windows',
                            a4_text: 'No windows',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: '4 windows'
                        }]
                },
                {
                    group_order: 4 - 5,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Миний нэрийг Adam Devine гэдэг. Миний монгол нэрийг Билгүүн \n' +
                            'гэдэг. Би хятад, англи, турк хэлээр ярьдаг. Би одоо монгол хэл сурч \n' +
                            'байна. Би 1986 онд төрсөн. Би одоо 32 настай. Би Америк улсаас ирсэн. \n' +
                            'Гэхдээ би Ирланд хүн. Миний гэр бүл Умард Ирландад байдаг. Миний \n' +
                            'аав хуульч, миний ээж эмч. Би Индианагийн их сургуулийн докторын \n' +
                            'оюутан. Би одоогоор блүүмингтон хотод <u>амьдарч</u> байна.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Which of the followings is NOT mentioned in the text?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Profession',
                            a2_text: 'Family',
                            a3_text: 'Future plan',
                            a4_text: 'Residency',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'The underlined word in Line 6 is the closest in meaning to which of the following?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'To live',
                            a2_text: 'To leave',
                            a3_text: 'To rest',
                            a4_text: 'To stay',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 6,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Баруун аймгуудын нутгаар энэ долоо хоногт бороо орохгүй, харин зүүн аймгуудаар нартай цэлмэг. Төвийн аймгуудын нутгаар үүлтэй. Улаанбаатар хотод үүлэрхэг, бага зэргийн бороотой.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the entire passage about?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Winter',
                            a2_text: 'Weather',
                            a3_text: 'Zud',
                            a4_text: 'Tsagaan Sar',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 7 - 9,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Худалдан авагч: Сайн байна уу? \n' +
                            'Худалдагч: Сайн сайн байна уу? \n' +
                            'Худалдан авагч: Тэр монгол гутал хэд вэ? \n' +
                            'Худалдагч: Тавин мянга \n' +
                            'Худалдан авагч: Ярих уу? \n' +
                            'Худалдагч: ярина ярина. Дөчин таван мянга\n' +
                            'Худалдан авагч: Танд өөр размер байгаа юу? \n' +
                            'Худалдагч: Байгаа. Та хэдэн размерийг хайж байна? \n' +
                            'Худалдан авагч: 41 размер байгаа юу? \n' +
                            'Худалдагч: Байлгүй яахав. За энэ байна. \n' +
                            'Худалдан авагч: Өмсөж үзэж болох уу? \n' +
                            'Худалдагч: Тэгэлгүй яахав. \n' +
                            'Худалдан авагч: за би энийг авая. \n' +
                            'Худалдагч: За май.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Where is the dialogue taking place?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'At a black market',
                            a2_text: 'At a grocery store',
                            a3_text: 'At a gas station',
                            a4_text: 'In a café',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'In line 5, ярих уу means:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'To sell',
                            a2_text: 'To buy',
                            a3_text: 'To exchange',
                            a4_text: 'To bargain',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'We can expect, in line 6, in response to customer that the salesman is willing to:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'To bargain',
                            a2_text: 'To sell',
                            a3_text: 'To buy',
                            a4_text: 'To help',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 10 - 12,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Би маш олон найзтай. Гэвч би маш цөөхөн үнэнч найзтай. Үнэнч сайн \n' +
                            'найзыг олох хэцүү. Үнэнч сайн найзууд бол ямарч зүйл болсон үргэлж \n' +
                            'чиний хажууд байж чамайг дэмжиж, урам өгдөг хүмүүсийг хэлнэ. \n' +
                            '\n' +
                            'Надад маш дотно гурван найз байдаг. Тэд нарын нэрийг Лагирмаа, \n' +
                            'Хулан бас Долгор гэдэг. Бид багаасаа найзалж байна. Бид бие биеэ маш \n' +
                            'сайн ойлгодог. Мэдээж заримдаа бид маргалддаг. Гэвч удалгүй бид \n' +
                            'буцаад эвлэрдэг. Ийм сайн найзуудтай байгаадаа би үргэлж бахархдаг.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'The passage is primarily concerned with which of the following?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Author’s friendship experience',
                            a2_text: 'Advice for friendship',
                            a3_text: 'About true friendship',
                            a4_text: 'Friendship’s benefits',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'As it is used in line 5, the word найзлах most nearly means:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Find a friend',
                            a2_text: 'Argue with a friend',
                            a3_text: 'Become friends',
                            a4_text: 'Make a friend',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'The main function of the second paragraph (lines 4 - 8) is to:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'show an example of finding a good friend',
                            a2_text: 'contrast a true friend with a false friend',
                            a3_text: 'show author’s friendship experience',
                            a4_text: 'identify a true friend',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 13 - 16,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Хүн бүр өөрсдийн хоббитой.  Ямар хоббитой байх нь тухайн хүний \n' +
                            'сонирхол, зан чанар мөн наснаас хамаардаг. Заримдаа хаана өсөж, ямар \n' +
                            'хүрээлэлд байгаагаас шалтгаалж хүн бүрийн хобби өөр өөр байдаг. \n' +
                            '\n' +
                            'Манай гэр бүлийн гишүүн бүр өөр өөрийн хоббитой. Миний өвөө олон \n' +
                            'адуутай. Тэр хурдан морьд дуртай. Харин миний аав нум сум харвах \n' +
                            'дуртай. Тэр энэ спортоор олон жил хичээллэж байна. \n' +
                            '\n' +
                            'Миний хувьд, би ном цуглуулж бас унших дуртай. Би дэлхийн олон \n' +
                            'алдартай зохиолчдын номыг уншсан. Миний найз Амартүвшин бас ном \n' +
                            'унших дуртай. Бид уулзах бүртээ уншсан номын талаар бие биедээ \n' +
                            'ярьж өгдөг. Хобби хүмүүст шинэ найзтай болоход нь тусладаг.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'The author of the passage mentioned how many of his family members:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'One',
                            a2_text: 'Two',
                            a3_text: 'Three',
                            a4_text: 'Four',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'Which of the following hobbies were NOT mentioned by the author?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Reading a book',
                            a2_text: 'Collecting a book',
                            a3_text: 'Archery',
                            a4_text: 'Comic books',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'It can be inferred that the author believes in the first paragraph that:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Everyone has different interests and hobbies and it depends on many factors',
                            a2_text: 'People tend to have same interests',
                            a3_text: 'Character doesn’t play much role in preferences and interests',
                            a4_text: 'Everyone is not supposed to have same interests',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'As used in line 3, хүрээлэлд most nearly means:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Environment',
                            a2_text: 'Animals',
                            a3_text: 'Friends',
                            a4_text: 'Family',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 17 - 19,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Монгол хүмүүс нүүдэлчин ард түмэн. Тиймээс Монголчууд таван \n' +
                            'хошуу малыг тэжээж, өөрсдийн хоол хүнс, уналга хөсөгт түлхүү \n' +
                            'ашигладаг. Таван хошуу малыг бод мал, бог мал хоёр хуваадаг. Бод мал \n' +
                            'буюу бүдүүн малд адуу, үхэр, тэмээ орно. Бог мал буюу бага малд \n' +
                            'хонь, ямаа багтана. Хонь, адуу, үхрийг халуун хошуут мал, ямаа, тэмээг \n' +
                            'хүйтэн хошуут мал гэдэг. Мөн хонь, ямаа, тэмээ салаа туурайт, адуу, \n' +
                            'үхэр битүү туурайт мал юм. \n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'It is clear from this passage that the author talks about:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Countryside life',
                            a2_text: 'Nomadic life',
                            a3_text: 'Mongolian livestock',
                            a4_text: 'Traditional holidays',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'It can be inferred that the livestock in Mongolia is primarily used for:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Food and transportation',
                            a2_text: 'Entertainment and transportation',
                            a3_text: 'Butchering and transportation',
                            a4_text: 'Transportation and selling',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'In the context of line 3 and 4, the phrase бод мал means',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Sheep, goat',
                            a2_text: 'Goat, sheep',
                            a3_text: 'Horse, cow, camel',
                            a4_text: 'Camel, sheep, goat',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 20 - 21,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Энэ бол манай анги. Манай ангийн дугаар 215. Манай анги сургуулийн \n' +
                            'гурван давхарт байдаг. Би ангидаа маш дуртай. Яагаад гэвэл манай \n' +
                            'анги их том бас их цэвэрхэн. Энэ 15 сурагчийн ширээ мөн 15 сурагчийн \n' +
                            'сандалтай. \n' +
                            '\n' +
                            'Үүнээс гадна, багшийн ширээ, сандал байдаг. \n' +
                            'Бид англи хэлний булантай. Энэ буланд бид англи хэлний сурах бичиг,\n' +
                            ' уран зохиолын ном, бусад хичээлийн материалуудыг тавьдаг. Долоо \n' +
                            'хоног бүр бид англи хэл дээр сонирхолтой мэдээлэл, шинэ дууны \n' +
                            'шүлэгийг тавьдаг. \n' +
                            '\n' +
                            'Манай ангийн 6 таазны гэрэлтэй мөн гурван том цонхтой. Цаг агаар \n' +
                            'дулаан байх үед, бид цонхоо онголгодог. Би өнөөдөр ангийн жижүүр. \n' +
                            'Тиймээс би өнөөдөр ангиа цэвэрлэнэ.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'The passage mentions each of the following EXCEPT…',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Classroom description',
                            a2_text: 'Classroom corner',
                            a3_text: 'Classroom schedule',
                            a4_text: 'Classroom duties',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'In last paragraph, the author of this passage primarily talked about…',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'His classroom duty',
                            a2_text: 'Weather',
                            a3_text: 'His classroom windows',
                            a4_text: 'His classmates',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 22 - 23,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Монгол гэрийн хойморт авдар байна. Авдар дээр бурхан, толь, гэр \n' +
                            'бүлийн 2зургийн цуглуулга гэх мэт зүйлс байдаг. Гэрийн баруун зүүн \n' +
                            'талаар ор байрладаг. Гэрийн зүүн тал бол эзэгтэйн тал. Гэрийн эзэн \n' +
                            'хойморт сууна. Ор авдар хоёрын дунд хувцасны шүүгээ юм уу өөр нэг \n' +
                            'ижил авдар байж болно. Баруун орны доор ихэнхидээ угаалгын булан \n' +
                            'эсвэл бусад эд хогшил их сав юмуу шүүгээ байдаг. Зүүн орны доор \n' +
                            'хөргөгч, эргэнэг байрладаг. Эргэнэг дээр элдэв сав суулга аяга шанага \n' +
                            'байна. Гэрийн голд зуух, яндан, модны сав, данх, харин хойморт ширээ \n' +
                            'сандал байрлана.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Which of the following would be the best title for this text?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Mongolian dwellings',
                            a2_text: 'Inside Mongolian Ger',
                            a3_text: 'The furniture in Mongolian Ger',
                            a4_text: 'How Mongolian Ger is built',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'In line 7, the word эргэнэг means:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Cupboard',
                            a2_text: 'Wardrobe',
                            a3_text: 'Stove',
                            a4_text: 'Sink',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 24 - 26,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Миний нэг өдөр маш завгүй өнгөрдөг. Өдөр бүр надад хийх ажил их \n' +
                            'байдаг. Би өглөө 6 цагт босдог. Би өглөө бүр дасгал хийдэг. Дасгалын \n' +
                            'дараа, би англи хэлний шинэ үгсээ давтдаг. Би одоо англи хэл сурч \n' +
                            'байгаа. Тийм учраас би өдөр бүр англи хэлээ давтахыг хичээдэг. Би \n' +
                            'ажилдаа 8 цагт очдог. Миний ажил гэртээ маань ойрхон. Би ихэнхидээ \n' +
                            'ажил руугаа алхаж явдаг. Би Төв шууданд ажилладаг. Миний ажил их \n' +
                            'хариуцлагатай. Ажил маань орой 6 цагт тардаг. Ажлын дараа, би \n' +
                            'үргэлж найзуудтайгаа уулздаг. Бид хамтдаа юм ярьж, зурагтаар хөл \n' +
                            'бөмбөг болон сагсан бөмбөг үздэг. Би гэртээ 10 цагийн үед очдог. Би \n' +
                            'хааяа оройн хоол хийдэг. Би 11 цагт унтдаг.\n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Which of the following activities did the author NOT do?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Studying English',
                            a2_text: 'Going to work',
                            a3_text: 'Seeing friends',
                            a4_text: 'Watching TV',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'How does the author commute to work?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'By bus',
                            a2_text: 'By taxi',
                            a3_text: 'On foot',
                            a4_text: 'Driving',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What can we infer from the passage about the author? \n' +
                                'The author is…\n',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Married',
                            a2_text: 'Busy',
                            a3_text: 'Unemployed',
                            a4_text: 'Employed',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 27 - 28,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Сайн байна уу? Намайг Болд гэдэг. Би монгол улсын иргэн. Би Улаанбаатар хотод төрсөн. Улаанбаатар хот Монгол улсын нийслэл бөгөөд хамгийн том хотуудын нэг юм. Миний аав ээж бас энэ хотод төрж өссөн. Улаанбаатар хотод нэг сая таван зуун мянган хүн амьдардаг. Хотын төвд байрлах талбайг Чингисийн тайлбай гэж нэрлэдэг. Байгалийн түүхийн музей, Үндэсний түүхийн музей, дуурийн театр, кино театр гэсэн олон амралт чөлөө цагаа өнгөрүүлэх газруудтай.',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the passage primarily about?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'About Bold',
                            a2_text: 'City issues',
                            a3_text: 'Lifestyle in city',
                            a4_text: 'City development',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the main square in Ulaanbaatar called?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Sukhbaatar square',
                            a2_text: 'Chinggis square',
                            a3_text: 'Red square',
                            a4_text: 'Freedom square',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 29 - 30,
                    group_type: 'text',
                    group_url: '',
                    group_iframe: 'text',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: 'Монгол туургатны нэг их баяр бол эрийн гурван наадам юм. Энэ \n' +
                            'наадам хүннүгийн үеэс улбаатай. Энэхүү наадам нь эр хүний бяр \n' +
                            'тэнхээ, эрхий хурууны хав мэргэ, эр морины шандас шалгадаг бөгөөд \n' +
                            'монголчуудын үндсэн спорт баярт цэнгүүн юм. \n' +
                            'Эрийн гурван наадамд, бөхийн барилдаан, сур харваа, хурдан морины \n' +
                            'уралдаан багтдаг. Монгол бөх нь 9 даваатай бөгөөд цолны эрэмбээр \n' +
                            'бие биетэй барилдана. Тохой, өвдөг газар хүрвэл унасанд тооцно. Нас \n' +
                            'бие жин харгалзахгүй. Барилдааны талбай хязгааргүй. Сур харваа нь \n' +
                            'монголчуудын хамгийн эртний тэмцээнүүдийн нэг юм. Цэц мэргэнээ \n' +
                            'үзэж нум сум харван, шалгарсан хүнд мэргэн цэл олгодо. Монголчууд \n' +
                            'эртнээс нааш морь, тэмээ уралдуулж ирсэн. Хурдан морины уралдаанд \n' +
                            'азарга, их нас, соёолон, хязаалан, шүдлэн, даага гэсэн насны \n' +
                            'ангилалаар уралдана. \n',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'In line 1, the word туургатны mostly means:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Nationality',
                            a2_text: 'Tradition',
                            a3_text: 'Custom',
                            a4_text: 'Practice',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'It can be inferred in the second paragraph that the participants in Mongolian Naadam are mostly:',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Children',
                            a2_text: 'Men',
                            a3_text: 'Women',
                            a4_text: 'Elders',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 1,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-01.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'According to the dialogue, why did she miss the class?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'She was at the conference.',
                            a2_text: 'She was sick.',
                            a3_text: 'She does not like the class.',
                            a4_text: 'She missed the bus.',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 2,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-02.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'How is she going to the Department store?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'On foot',
                            a2_text: 'By bus',
                            a3_text: 'By taxi',
                            a4_text: 'By motorcycle',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'Where is the Department Store located?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Near the Museum',
                            a2_text: 'In Peace Avenue',
                            a3_text: 'Behind park',
                            a4_text: 'Near the library',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 3,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-03.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'According to the dialogue, who is Anujin?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Saruul’s sister',
                            a2_text: 'Aldar’s mother',
                            a3_text: 'Hishgee’s girlfriend',
                            a4_text: 'Aldar’s sister-in law',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'According to the conversation, who does not have grandparents?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Hishgee',
                            a2_text: 'Saruul',
                            a3_text: 'Aldar',
                            a4_text: 'Aldar’s wife',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 4,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-04.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Which entertainment did the two friends decide to enjoy?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'ballet',
                            a2_text: 'opera',
                            a3_text: 'movie',
                            a4_text: 'drama',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'Where did they agree to meet?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Near the park',
                            a2_text: 'On the bus stop',
                            a3_text: 'Inside the theater',
                            a4_text: 'At the entrance of the theater',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 5,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-05.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'To what event did Tuya invite her friend?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Wedding party',
                            a2_text: 'Graduation ceremony',
                            a3_text: 'Birthday party',
                            a4_text: 'Farewell party',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What will Bilguun bring to celebrate?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Salad and soda',
                            a2_text: 'Wine and ice-cream',
                            a3_text: 'Chocolate and a card',
                            a4_text: 'Flower and cake',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What time are the guests coming?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '5 pm',
                            a2_text: '7 pm',
                            a3_text: '9:00 am',
                            a4_text: 'Any time they want.',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 6,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-06.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What would the customer like to buy?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Dress',
                            a2_text: 'Cup',
                            a3_text: 'Shirt',
                            a4_text: 'Skirt',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the favorite color of the customer?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'White',
                            a2_text: 'Red',
                            a3_text: 'Blue',
                            a4_text: 'Green',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 7,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-07.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is today’s US Dollar Exchange Rate?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '2,539 MNT',
                            a2_text: '2,639 MNT',
                            a3_text: '2,439 MNT',
                            a4_text: '2,659 MNT',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: ' How much dollars did John exchange?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '$500',
                            a2_text: '$400',
                            a3_text: '$300',
                            a4_text: '$200',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 8,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-08.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What food did Tuul order?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Fried fish and coffee',
                            a2_text: 'Dumpling and black tea',
                            a3_text: 'Chicken and green tea',
                            a4_text: 'Sandwich and soda',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },

                {
                    group_order: 9,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-09.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the girl’s profession?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'librarian',
                            a2_text: 'doctor',
                            a3_text: 'teacher',
                            a4_text: 'driver',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'For how long has she been working at the hospital?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Five years',
                            a2_text: 'Six years',
                            a3_text: 'Two years',
                            a4_text: 'Seven years',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 10,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-10.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Which sports competition will be organized at the University?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Volleyball',
                            a2_text: 'Basketball',
                            a3_text: 'Swimming',
                            a4_text: 'Wrestling',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 11,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-11.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the advertisement about?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Book shop',
                            a2_text: 'Car rent',
                            a3_text: 'Apartment rent',
                            a4_text: 'Driving test',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'When is it recommended to contact the advertiser?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'In the morning',
                            a2_text: 'During the day',
                            a3_text: 'In the evening',
                            a4_text: 'After 10 pm',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 12,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-12.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the temperature tomorrow in UB?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '– 5C to 7C',
                            a2_text: '+ 3C to  5 C',
                            a3_text: '+ 5C to 7C',
                            a4_text: '+ 7C to 9C',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 13,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-13.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'In what fields does the Peace Corps in Mongolia operate?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Mechanics, service, inventions',
                            a2_text: 'Animal husbandry, pastures, agriculture',
                            a3_text: 'English language, youth development, health',
                            a4_text: 'State institutions, local development, governance',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'Where is PC funded from?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'The government of the host country',
                            a2_text: 'Government of the USA',
                            a3_text: 'Funds of community organizations',
                            a4_text: 'Individual donations',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 14,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-14.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What kind of sports does he play?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Running',
                            a2_text: 'Volleyball',
                            a3_text: 'Wrestling',
                            a4_text: 'Yoga',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'At what age did he become a World Champion?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '55',
                            a2_text: '65',
                            a3_text: '75',
                            a4_text: '85',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 15,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-15.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the current population of Mongolia?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '3,500,000',
                            a2_text: '3,300,000',
                            a3_text: '2,300,000',
                            a4_text: '4,100,000',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What is the most important sector of Mongolian economy?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Mining',
                            a2_text: 'Agriculture',
                            a3_text: 'Livestock',
                            a4_text: 'Tourism',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'In what year did Mongolia become the member of the UN?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '1921',
                            a2_text: '1961',
                            a3_text: '1957',
                            a4_text: '1961',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 16,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: '',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '/assets/audios/Audio-16.mp3',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: 'Where did he defend his doctorate degree?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'Harvard University',
                            a2_text: 'University of Pennsylvania',
                            a3_text: 'Indiana University',
                            a4_text: 'National University of Mongolia',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        },
                        {
                            q_difficulty: 5,
                            q_text: 'What is his main research topic about?',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: 'The secret history of Mongolia',
                            a2_text: 'History of Mongolian Family',
                            a3_text: 'Mongolians of the 20th Century',
                            a4_text: 'History of the Mongol Empire',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 1,
                    group_type: 'video',
                    group_url: '.mp4',
                    group_iframe: 'text with styles',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a5_text: '',
                        a6_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        a5_url: '',
                        a6_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: '',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '',
                            a2_text: '',
                            a3_text: '',
                            a4_text: '',
                            a5_text: '',
                            a6_text: '',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            a5_url: '',
                            a6_url: '',
                            correct_answer: ''
                        }]
                },
                {
                    group_order: 2,
                    group_type: 'audio',
                    group_url: '.mp3',
                    group_iframe: 'text with styles',
                    group_questions: [{
                        q_difficulty: 3,
                        q_text: '',
                        q_audio: '',
                        q_video: '',
                        q_picture: '',
                        a1_text: '',
                        a2_text: '',
                        a3_text: '',
                        a4_text: '',
                        a5_text: '',
                        a6_text: '',
                        a1_url: '',
                        a2_url: '',
                        a3_url: '',
                        a4_url: '',
                        a5_url: '',
                        a6_url: '',
                        correct_answer: ''
                    },
                        {
                            q_difficulty: 5,
                            q_text: '',
                            q_audio: '',
                            q_video: '',
                            q_picture: '',
                            a1_text: '',
                            a2_text: '',
                            a3_text: '',
                            a4_text: '',
                            a5_text: '',
                            a6_text: '',
                            a1_url: '',
                            a2_url: '',
                            a3_url: '',
                            a4_url: '',
                            a5_url: '',
                            a6_url: '',
                            correct_answer: ''
                        }]
                }];

            this.setService.addSet(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((message: any): void => {
                    this.addSetProcessInProgress = true;
                    this.successMessage = message.message;
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
            this.addSetProcessInProgress = true;
        }
        this.errorMessage = null;
    }

    ngOnInit(): void {
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
                this.initMultipleChoiceForm();
                console.log(this.currentUser);
            });
    }

    private initMultipleChoiceForm(): void {
        this.multipleChoiceForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            instructions: ['', [Validators.required]],
            type: [''],
            langDirection: ['', [Validators.required]],
            duration: ['', [Validators.required]],
            option: ['', [Validators.required]],
            text: [''],
            questions: ['', [Validators.required]]
        });
    }


    /*onShowFileManager(item: any): void {
        // close function
    };

    onShowFile(item: any): void {
        // close function
    };

    removeRow(item: any): void {
        // close function
    };

    addAnswer(item: any): void {
        // close function
    };

    removeAnswer(item: any): void {
        // close function
    };

    checkAnswer(item: any): void {
        // close function
    };


    onClose(): void {
        // close function
    };

    onSave(): void {
        // save function
    };

    addRow(): void {
        // add row function
    };*/
}




