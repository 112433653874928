import {ActivatedRoute, Router} from '@angular/router';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ConfigService} from '../../modules/config/config.service';


@Component({
    selector: 'dashboard-page',
    templateUrl: 'dashboard-page.component.html',
    styleUrls: ['dashboard-page.component.scss']
})
export class DashboardPageComponent implements AfterViewInit, OnInit {

    currentUser: any;
    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private userService: UserService) {
        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
                // console.log(this.currentUser);
            });
    }
}
