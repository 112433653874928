import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../modules/config/config.service';
import {AuthService} from '../../services/auth.service';
import {takeUntil} from 'rxjs/internal/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {TestService} from '../../services/test.service';
import {Subject} from 'rxjs';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-add-test-page',
  templateUrl: './add-test-page.component.html',
  styleUrls: ['./add-test-page.component.scss']
})
export class AddTestPageComponent implements OnInit, OnDestroy {

    apiUrl: string;
    data: any = {};
    successMessage = '';
    errorMessage = '';
    addTestForm: FormGroup;
    addTestProcessInProgress: boolean;
    currentUser: any;

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private userService: UserService,
                private testService: TestService,
                private formBuilder: FormBuilder) {

        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    onClose(): void {
        this.errorMessage = null;
        this.successMessage = null;
        this.addTestProcessInProgress = false;
    }

    oauth(provider: string): void {
        window.open(this.apiUrl + '/oauth/' + provider, '_self');
    }

    onSubmitAddTestForm(): void {
        if (this.addTestForm.valid) {
            const data: any = {};
            data.userId = this.currentUser.id;
            data.userRole = this.currentUser.role;
            data.title = this.addTestForm.controls.title.value;
            data.description = this.addTestForm.controls.description.value;
            data.langDirection = this.addTestForm.controls.langDirection.value;
            data.isPublic = this.addTestForm.controls.isPublic.value;
            this.testService.addTest(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((message: any): void => {
                    this.addTestProcessInProgress = true;
                    this.successMessage = message.message;
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
            this.addTestProcessInProgress = true;
        }
        this.errorMessage = null;
    }

    ngOnInit(): void {
        // this.initAddTestForm();
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
                this.initAddTestForm();
                console.log(this.currentUser);
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private initAddTestForm(): void {
        this.addTestForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            isPublic: [''],
            langDirection: ['']
        });
    }

}
