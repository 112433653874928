import {Component, Input, OnInit} from '@angular/core';
import {Subject, timer} from 'rxjs';

import {ConfigService} from '../../../modules/config/config.service';
import {TestGroup, TestQuestion, TestResults, TestSet, TestStatus} from '../../interfaces/test.interface';
import {TestPlayerService} from '../../../services/test-player.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'test-player-set',
    templateUrl: 'test-player-set.component.html',
    styleUrls: ['test-player-set.component.scss']
})
export class TestPlayerSetComponent implements OnInit {

    @Input() data: TestSet;
    @Input() i: number;

    currentGroup: TestGroup;
    currentTestResults: TestResults;
    currentTestStatus: TestStatus;
    set: TestSet;

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService,
                private testPlayerService: TestPlayerService) {
    }

    onFinish(): void {
    }

    onStart(): void {
        this.testPlayerService.nextGroup();
    }

    ngOnInit(): void {
        this.set = this.data;

        this.testPlayerService.currentTestResults$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((testResults: TestResults): void => {
                this.currentTestResults = testResults;
            });

        this.testPlayerService.currentTestStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((testStatus: TestStatus): void => {
                this.currentTestStatus = testStatus;
                this.currentGroup = this.set.groups[this.currentTestStatus.currentGroupIndex];
                timer(0)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((): void => {
                        this.set = this.data;
                    });
            });
    }
}
