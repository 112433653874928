import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs/index';
import {SnotifyService} from 'ng-snotify';

import {environment} from '../../../environments/environment';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';

@Injectable()
export class ConfigService implements OnDestroy {

    mainRouterOutletData$ = new ReplaySubject(1);

    private _apiUrl: string;
    private _mainRouterOutletData: any = {};

    constructor(private snotifyService: SnotifyService) {
        this.apiUrl = environment.apiUrl;
    }

    set apiUrl(url: string) {
        this._apiUrl = url;
    }

    get apiUrl(): string {
        return this._apiUrl;
    }

    set mainRouterOutletData(data: any) {
        this._mainRouterOutletData = data;
        this.mainRouterOutletData$.next(this._mainRouterOutletData);
    }

    get mainRouterOutletData(): any {
        return this._mainRouterOutletData;
    }

    errorHandler(error: TtErrorResponse): void {
        const data = error.data || error;
        const message = error.message || data.errmsg || 'Unknown error'; // errmsg - mongodb error.
        this.snotifyService.error(
            message,
            {
                position: 'rightTop'
            }
        );
        console.log('Err:', data);
    }

    getItemPropById(_id: string, prop: string, collection: any[]): string | undefined {
        if (collection && collection.length) {
            for (let i = 0; i < collection.length; i++) {
                if (collection[i]._id === _id) {
                    return collection[i][prop];
                }
            }
        }
        return undefined;
    }

    handler(message: string): void {
        this.snotifyService.success(
            message,
            {
                position: 'rightTop'
            }
        );
    }

    ngOnDestroy(): void {
    }
}
