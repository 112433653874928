import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
    selector: 'confirm-email-page',
    templateUrl: 'confirm-email-page.component.html',
    styleUrls: ['confirm-email-page.component.scss']
})
export class ConfirmEmailPageComponent implements AfterViewInit, OnInit {

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }
}
