import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sets-page',
  templateUrl: './sets-page.component.html',
  styleUrls: ['./sets-page.component.scss']
})
export class SetsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
