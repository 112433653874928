import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'ui-footer',
    templateUrl: 'ui-footer.component.html',
    styleUrls: ['ui-footer.component.scss']
})
export class UIFooterComponent implements AfterViewInit, OnDestroy, OnInit {

    private bodyElem: any = document.getElementsByTagName('app-root')[0];

    constructor(private elementRef: ElementRef,
                private storageService: StorageService,
                private translateService: TranslateService) {
        this.translateService.setDefaultLang('en');
        const storedLang = this.storageService.getItem('lang');
        this.translateService.use(storedLang || 'en');
    }

    onChangeCurrentLang(): void {
        if (this.translateService.currentLang === 'en') {
            this.storageService.setItem('lang', 'es');
            this.translateService.use('es');
        } else if (this.translateService.currentLang === 'es') {
            this.storageService.setItem('lang', 'en');
            this.translateService.use('en');
        }
    }

    onWindowResize(event: any): void {
        this.bodyElem.style.paddingBottom = this.elementRef.nativeElement.offsetHeight + 'px';
    }

    ngAfterViewInit(): void {
        this.bodyElem.style.paddingBottom = this.elementRef.nativeElement.offsetHeight + 'px';
    }

    ngOnDestroy(): void {
        this.bodyElem.style.paddingBottom = '';
    }

    ngOnInit(): void {
    }
}
