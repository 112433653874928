import 'hammerjs'; // Gesture Support for Angular Material
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';

// BEGIN: NGX-Translate
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
// END: NGX-Translate

import {AppRouting} from './app.routing';
import {ConfigModule} from './modules/config/config.module';
import {SharedModule} from './shared/shared.module';

import {AboutPageComponent} from './components/about-page/about-page.component';
import {AppComponent} from './app.component';
import {ConfirmEmailPageComponent} from './components/confirm-email-page/confirm-email-page.component';
import {ContactUsPageComponent} from './components/contact-us-page/contact-us-page.component';
import {DashboardPageComponent} from './components/dashboard-page/dashboard-page.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {SignInPageComponent} from './components/sign-in-page/sign-in-page.component';
import {SignUpPageComponent} from './components/sign-up-page/sign-up-page.component';
import {ForgotPasswordPageComponent} from './components/forgot-password-page/forgot-password-page.component';
import {UIFooterComponent} from './components/ui-footer/ui-footer.component';
import {UIHeaderComponent} from './components/ui-header/ui-header.component';

import {AuthGuard} from './services/auth.guard';
import {AuthInterceptor} from './services/auth.interceptor';
import {AuthService} from './services/auth.service';
import {CurrentUserResolver} from './resolvers/current-user.resolver';
import {DomService} from './services/dom.service';
import {FileService} from './services/file.service';
import {StorageService} from './services/storage.service';
import {UserService} from './services/user.service';
import {TestService} from './services/test.service';
import {SetService} from './services/set.service';
import { TestsPageComponent } from './components/tests-page/tests-page.component';
import { AddTestPageComponent } from './components/add-test-page/add-test-page.component';
import { SetsPageComponent } from './components/sets-page/sets-page.component';
import { AddSetPageComponent } from './components/add-set-page/add-set-page.component';
import {NgxEditorModule} from 'ngx-editor';
import {TestsComponent} from './components/tests/tests.component';
import {Test2PageComponent} from './components/test2-page/test2-page.component';
import {TestPlayerComponent} from './shared/components/test-player/test-player.component';
import {TestPlayerService} from './services/test-player.service';

@NgModule({
    declarations: [
        AboutPageComponent,
        AppComponent,
        ConfirmEmailPageComponent,
        ContactUsPageComponent,
        DashboardPageComponent,
        HomePageComponent,
        SignInPageComponent,
        SignUpPageComponent,
        ForgotPasswordPageComponent,
        UIFooterComponent,
        TestPlayerComponent,
        UIHeaderComponent,
        TestsComponent,
        TestsPageComponent,
        Test2PageComponent,
        AddTestPageComponent,
        AddSetPageComponent,
        SetsPageComponent,
    ],
    entryComponents: [
        TestPlayerComponent,
    ],
    imports: [
        AppRouting,
        BrowserAnimationsModule,
        BrowserModule,
        ConfigModule.forRoot(),
        FormsModule,
        HttpClientModule,
        NgxEditorModule,
        ReactiveFormsModule,
        SharedModule,
        SnotifyModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AuthGuard,
        AuthService,
        CurrentUserResolver,
        DomService,
        TestPlayerService,
        FileService,
        SnotifyService,
        StorageService,
        UserService,
        TestService,
        SetService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: 'SnotifyToastConfig',
            useValue: ToastDefaults
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
