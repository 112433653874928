import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ConfigService} from '../../../modules/config/config.service';
import {Test, TestResults, TestSet, TestStatus} from '../../interfaces/test.interface';
import {TestPlayerService} from '../../../services/test-player.service';

@Component({
    selector: 'test-player',
    templateUrl: 'test-player.component.html',
    styleUrls: ['test-player.component.scss']
})
export class TestPlayerComponent implements OnDestroy, OnInit {

    @Input() data: Test;

    currentSet: TestSet;
    currentTestResults: TestResults;
    currentTestStatus: TestStatus;

    private ngUnsubscribe = new Subject<void>();

    constructor(private element: ElementRef,
                private configService: ConfigService,
                private testPlayerService: TestPlayerService) {

    }

    onStart(): void {
        this.testPlayerService.nextSet();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.testPlayerService.startTest(this.data, 'Akmal');

        this.testPlayerService.currentTestResults$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((testResults: TestResults): void => {
                this.currentTestResults = testResults;
                console.log(this.currentTestResults);
            });

        this.testPlayerService.currentTestStatus$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((testStatus: TestStatus): void => {
                this.currentTestStatus = testStatus;
                this.currentSet = this.data.sets[this.currentTestStatus.currentSetIndex];
                console.log(this.currentTestStatus);
                this.element.nativeElement.scrollTo(0, 0);
            });

        document.addEventListener('play', (e): void => {
            const audios = document.getElementsByTagName('audio');
            for (let i = 0; i < audios.length; i++) {
                if (audios[i] !== e.target) {
                    audios[i].pause();
                }
            }
            const videos = document.getElementsByTagName('video');
            for (let i = 0; i < videos.length; i++) {
                if (videos[i] !== e.target) {
                    videos[i].pause();
                }
            }
        }, true);
    }
}
