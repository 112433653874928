import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
    selector: 'home-page',
    templateUrl: 'home-page.component.html',
    styleUrls: ['home-page.component.scss']
})

export class HomePageComponent implements AfterViewInit, OnInit {


    constructor() {  }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }

}
