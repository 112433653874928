import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../modules/config/config.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

export interface Answers {
    stringValue: string;
    stringViewValue: string;
}

@Component({
    selector: 'app-contact-us-page',
    templateUrl: 'contact-us-page.component.html',
    styleUrls: ['contact-us-page.component.scss']
})

export class ContactUsPageComponent implements AfterViewInit, OnInit {
    apiUrl: string;
    data: any = {};
    successMessage = '';
    errorMessage = '';
    benAdminForm: FormGroup;
    benAdminForm1: FormGroup;
    benAdminForm2: FormGroup;
    benAdminForm3: FormGroup;
    benAdminProcessInProgress: boolean;

    step = 0;

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    onClose() {
       //close message
    }

    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    currentStateAnswers: Answers[] = [
        {stringValue: 'On-premise system (internally managed)', stringViewValue: 'On-premise system (internally managed)'},
        {stringValue: 'Hosted system (externally managed)', stringViewValue: 'Hosted system (externally managed)'},
        {stringValue: 'Not applicable', stringViewValue: 'Not applicable'}
    ]

    futureDesiredStateAnswers: Answers[] = [
        {stringValue: 'Maintain current site', stringViewValue: 'Maintain current site'},
        {stringValue: 'Hosted system - move to new vendor', stringViewValue: 'Hosted system - move to new vendor'},
        {stringValue: 'Hosted system - keep current vendor', stringViewValue: 'Hosted system - keep current vendor'},
        {stringValue: 'Not interested', stringViewValue: 'Not interested'},
        {stringValue: 'Not applicable', stringViewValue: 'Not applicable'}
    ]

    isThisInScopeForProjectAnswers: Answers[] = [
        {stringValue: 'In scope', stringViewValue: 'In scope'},
        {stringValue: 'Possibly in scope', stringViewValue: 'Possibly in scope'},
        {stringValue: 'Not in scope', stringViewValue: 'Not in scope'},
        {stringValue: 'Not applicable', stringViewValue: 'Not applicable'}
    ]

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private formBuilder: FormBuilder,
                private router: Router,
                private formBuilder2: FormBuilder) {
        this.apiUrl = this.configService.apiUrl;
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.initForms();
        this.firstFormGroup = this.formBuilder2.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this.formBuilder2.group({
            secondCtrl: ['', Validators.required]
        });
    }

    private initForms(): void {
        this.benAdminForm = this.formBuilder.group({
            currentSystem: ['', [Validators.required]],
            currentStateAnswers: ['', [Validators.required]],
            futureDesiredStateAnswers: ['', [Validators.required]],
            isThisInScopeForProjectAnswers: ['', [Validators.required, Validators.email]],
        }, {});
    }

    onSubmitBenAdminForm(): void {
        if (this.benAdminForm.valid) {

            const data: any = {};
            data.currentSystem = this.benAdminForm.controls.currentSystem.value;
            data.currentStateAnswers = this.benAdminForm.controls.currentStateAnswers.value;
            data.futureDesiredStateAnswers = this.benAdminForm.controls.futureDesiredStateAnswers.value;
            data.isThisInScopeForProjectAnswers = this.benAdminForm.controls.isThisInScopeForProjectAnswers.value;

           // Add formService and a ctivate this code
           /*this.formService.submitBenAdminForm(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((message: string): void => {
                    this.benAdminProcessInProgress = true;
                    this.successMessage = 'We have sent you an email with a link to activate your account. Please check.';
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
            this.benAdminProcessInProgress = true;
            */
        }

        this.errorMessage = null;
    }

}
