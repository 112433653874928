import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
    selector: 'about',
    templateUrl: 'about-page.component.html',
    styleUrls: ['about-page.component.scss']
})
export class AboutPageComponent implements AfterViewInit, OnInit {

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
    }
}
