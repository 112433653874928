import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {ConfigService} from '../../../modules/config/config.service';
import {TtDialog} from '../../interfaces/dialog.interface';

@Component({
    selector: 'simple-image-viewer-dialog',
    templateUrl: 'simple-image-viewer-dialog.component.html',
    styleUrls: ['simple-image-viewer-dialog.component.scss']
})
export class SimpleImageViewerDialogComponent implements OnInit {

    apiUrl: string;
    photoUrl: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: TtDialog,
                public dialogRef: MatDialogRef<SimpleImageViewerDialogComponent>,
                private configService: ConfigService) {
        this.apiUrl = this.configService.apiUrl;
    }

    onCancel(): void {
        this.dialogRef.close(null);
    }

    onConfirm(): void {
        this.dialogRef.close(null);
    }

    ngOnInit(): void {
        this.photoUrl = this.data.dialogData.photoUrl;
    }
}
