import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../modules/config/config.service';
import {Subject} from 'rxjs';
import {TestAnswer, TestQuestion} from '../../interfaces/test.interface';

@Component({
    selector: 'app-test-question',
    templateUrl: 'test-question.component.html',
    styleUrls: ['test-question.component.scss']
})
export class TestQuestionComponent implements OnInit {

    @Input() data: TestQuestion;
    @Input() i: number;

    newAnswer: TestAnswer = {
        type: 'text',
        isCorrect: true
    };

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService) {
    }

    onSelect(event: Event): void {
        const file = event.target['files'][0];
        this.newAnswer.type = file.type.split('/')[0];
        this.newAnswer.answer = file.name;
    }

    onSubmit(): void {
        this.data.answers.push(this.newAnswer);
        this.newAnswer = {
            type: 'text',
            isCorrect: true
        };
    }

    ngOnInit(): void {
    }
}
