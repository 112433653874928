import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';

import {CommonUtil} from '../../utils/common.util';
import {ConfigService} from '../../../modules/config/config.service';
import {TtCroppedImage} from '../../interfaces/file-data.interface';
import {TtDialog} from '../../interfaces/dialog.interface';

@Component({
    selector: 'image-cropper-dialog',
    templateUrl: 'image-cropper-dialog.component.html',
    styleUrls: ['image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {

    aspectRatio: number;
    cancelButtonText: string;
    confirmButtonText: string;
    imageChangedEvent: any = '';
    onlyScaleDown: boolean;
    resizeToWidth: number;

    private croppedImage: any = '';
    private ngUnsubscribe = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: TtDialog,
                public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
                private configService: ConfigService,
                private translateService: TranslateService) {
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onConfirm(): void {
        CommonUtil.getImageDimensions(this.croppedImage)
            .then((dimensions: { width: number; height: number }): void => {
                const dialogResult: TtCroppedImage = {
                    base64: this.croppedImage.replace(/^data:image\/png;base64,/, ''),
                    width: dimensions.width,
                    height: dimensions.height,
                    format: 'png'
                };
                this.dialogRef.close(dialogResult);
            });
    }

    onImageCropped(dataUrl: string): void {
        this.croppedImage = dataUrl;
    }

    onImageLoaded(): void {
        // show cropper
    }

    onLoadImageFailed(): void {
        // show message
    }

    onTranslationsLoaded(translations: any): void {
        if (this.data.dialogActionBar) {
            this.cancelButtonText = this.data.dialogActionBar.cancelButtonText || translations.cancel;
            this.confirmButtonText = this.data.dialogActionBar.confirmButtonText || translations.yes;
        }
    }

    ngOnInit(): void {
        if (this.data.dialogData) {
            this.aspectRatio = this.data.dialogData.aspectRatio || 1;
            this.imageChangedEvent = this.data.dialogData.imageChangedEvent;
            this.onlyScaleDown = this.data.dialogData.onlyScaleDown || false;
            this.resizeToWidth = this.data.dialogData.resizeToWidth;
        }

        this.translateService.get(['confirmDialog'])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((translations: any): void => {
                this.onTranslationsLoaded(translations.confirmDialog);
            }, (err: Error): void => {
                this.configService.errorHandler(err);
            });
    }
}
