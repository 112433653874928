import {catchError, map} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs/index';

import {ConfigService} from '../modules/config/config.service';
import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {Test} from '../shared/interfaces/test.interface';

@Injectable()
export class TestService {

    private apiUrl: string;

    constructor(private configService: ConfigService,
                private http: HttpClient) {
        this.apiUrl = this.configService.apiUrl;
    }

    retrieveByTestId(id: string): Observable<any | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/read_one.php?id=' + id)
            .pipe(
                map((res: any): any => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    search(params?: string): Observable<Test[] | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/search' + (params ? '?' + params : ''))
            .pipe(
                map((res: Test[]): Test[] => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    update(user: Test): Observable<Test | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_test.php', user)
            .pipe(
                map((res: Test): Test => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    deleteTestByTestId(testId: string, userId: string, username: string): Observable<string | TtErrorResponse> {
        const data: any = {};
        data.testId = testId;
        data.userId = userId;
        data.username = username;
        return this.http.post(this.apiUrl + '/delete_test.php', data)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }


    // refactor any type to Test
    addTest(testData: any): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/create_test.php', testData)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    // Retrieve all tests for this/authenticated user
    retrieveAllTestsByUserId(userId: any): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/get_tests.php', userId)
            .pipe(
                map((results: any): any => {
                    return results;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

}
