import {CommonModule} from '@angular/common';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatPaginatorIntl} from '@angular/material';
import {NgModule} from '@angular/core';
import {NgxMaskModule} from 'ngx-mask';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {MaterialModule} from './modules/material/material.module';

import {ConfirmDialogComponent} from './dialogs/confirm/confirm-dialog.component';
import {ImageCropperDialogComponent} from './dialogs/image-cropper/image-cropper-dialog.component';
import {NotFoundPageComponent} from './components/not-found-page/not-found-page.component';
import {SimpleImageViewerDialogComponent} from './dialogs/simple-image-viewer/simple-image-viewer-dialog.component';

import {CustomMatPaginatorIntl} from './modules/material/custom-mat-paginator.intl';
import {TestAnswerComponent} from './components/test-answer/test-answer.component';
import {TestQuestionComponent} from './components/test-question/test-question.component';
import {FormsModule} from '@angular/forms';
import {TestPlayerQuestionComponent} from './components/test-player-question/test-player-question.component';
import {TestPlayerSetComponent} from './components/test-player-set/test-player-set.component';
import {TestPlayerGroupComponent} from './components/test-player-group/test-player-group.component';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        ImageCropperDialogComponent,
        NotFoundPageComponent,
        SimpleImageViewerDialogComponent,
        TestAnswerComponent,
        TestQuestionComponent,
        TestPlayerQuestionComponent,
        TestPlayerSetComponent,
        TestPlayerGroupComponent
    ],
    entryComponents: [
        ConfirmDialogComponent,
        ImageCropperDialogComponent,
        SimpleImageViewerDialogComponent,
    ],
    imports: [
        CommonModule,
        ImageCropperModule,
        MaterialModule,
        NgxMaskModule.forRoot(),
        RouterModule,
        TranslateModule,
        FormsModule,
    ],
    exports: [
        ConfirmDialogComponent,
        ImageCropperDialogComponent,
        MaterialModule,
        NgxMaskModule,
        NotFoundPageComponent,
        SimpleImageViewerDialogComponent,
        TranslateModule,
        TestQuestionComponent,
        TestPlayerQuestionComponent,
        TestPlayerSetComponent,
        TestPlayerGroupComponent
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl
        }
    ]
})
export class SharedModule {
}
