import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';

import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const isLoggedIn = this.authService.isLoggedIn();

        if (next.data && next.data['mustLoggedOut']) {
            return !isLoggedIn;
        }

        if (isLoggedIn) {
            return true;
        } else {
            this.router.navigate(['sign-in'], {queryParams: {next: next.url}});
            return false;
        }
    }
}
