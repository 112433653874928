import {catchError} from 'rxjs/internal/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs/index';

import {StorageService} from './storage.service';
import {UserService} from './user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService,
                private userService: UserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let customReq;
        let headers = req.headers;

        // Set the default 'Content-Type' header
        if (!headers.has('Content-Type')) {
            headers = headers.set('Content-Type', 'application/json');
        }

        // if (!headers.has('APIKey')) {
        //    headers = headers.set('APIKey', 'THE_BEST_API-KEY_IN_THE_W0RLD');
        // }

        const token = this.storageService.getItem('token');
        if (token) {
            headers = headers.set('Authorization', `${token}`);
        } else {
            headers = headers.delete('Authorization');
        }

        customReq = req.clone({
            headers: headers
        });

        return next
            .handle(customReq)
            .pipe(
                catchError((err: any): Observable<any> => {
                    if (err.status === 401) {
                        this.storageService.removeItem('token');
                        this.userService.currentUser = null;
                    }

                    let error: any;
                    try {
                        error = JSON.parse(err.error);
                    } catch (e) {
                        error = err.error;
                    }
                    return throwError(error);
                })
            );
    }
}
