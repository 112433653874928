import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../modules/config/config.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {takeUntil} from 'rxjs/internal/operators';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {TestService} from '../../services/test.service';
import {MatDialog} from '@angular/material';
import {SimpleImageViewerDialogComponent} from '../../shared/dialogs/simple-image-viewer/simple-image-viewer-dialog.component';

@Component({
    selector: 'app-tests-page',
    templateUrl: './tests.component.html',
    styleUrls: ['./tests.component.scss']
})
export class TestsComponent implements AfterViewInit, OnInit {

    currentUser: any;
    userTests: any;
    successMessage = '';
    errorMessage = '';
    deleteTestProcessInProgress: boolean;
    private ngUnsubscribe = new Subject<void>();

    constructor(public dialog: MatDialog,
                private authService: AuthService,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private userService: UserService,
                private testService: TestService) {

        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    onSelectingImage(src: string): void {
        const dialogRef = this.dialog.open(SimpleImageViewerDialogComponent, {
            maxHeight: '90vh',
            data: {
                dialogData: {
                    photoUrl: src
                }
            }
        });
        dialogRef.afterClosed();
    };

    retrieveAllTestsByUserId(): void {
        this.testService.retrieveAllTestsByUserId(this.currentUser.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any): void => {
                this.userTests = res;
            }, (err: TtErrorResponse): void => {
                this.configService.errorHandler(err);
            });
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
                this.retrieveAllTestsByUserId();
                // console.log(this.currentUser);
            });
        document.addEventListener('play', (e): void => {
            const audios = document.getElementsByTagName('audio');
            for (let i = 0, len = audios.length; i < len; i++) {
                if (audios[i] !== e.target) {
                    audios[i].pause();
                }
            }
        }, true);
        document.addEventListener('play', (e): void => {
            const videos = document.getElementsByTagName('video');
            for (let i = 0, len = videos.length; i < len; i++) {
                if (videos[i] !== e.target) {
                    videos[i].pause();
                }
            }
        }, true);
    }

}
