import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../modules/config/config.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {takeUntil} from 'rxjs/internal/operators';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {FormGroup} from '@angular/forms';
import {TestService} from '../../services/test.service';

@Component({
  selector: 'app-tests-page',
  templateUrl: './tests-page.component.html',
  styleUrls: ['./tests-page.component.scss']
})
export class TestsPageComponent implements AfterViewInit, OnInit {

    currentUser: any;
    userTests: any;
    successMessage = '';
    errorMessage = '';
    deleteTestProcessInProgress: boolean;
    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private userService: UserService,
                private testService: TestService) {

        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    retrieveAllTestsByUserId(): void {
        this.testService.retrieveAllTestsByUserId(this.currentUser.id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: any): void => {
                    this.userTests = res;
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
    }

    deleteTestByTestId(testId: string): void {
            this.testService.deleteTestByTestId(testId, this.currentUser.id, this.currentUser.email)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((message: any): void => {
                    this.deleteTestProcessInProgress = true;
                    this.successMessage = message.message;
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                });
            this.deleteTestProcessInProgress = true;
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
                this.retrieveAllTestsByUserId();
                // console.log(this.currentUser);
            });
    }

}
