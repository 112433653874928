import {ActivatedRoute, Router} from '@angular/router';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, timer} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../modules/config/config.service';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-sign-in-page',
    templateUrl: 'sign-in-page.component.html',
    styleUrls: ['sign-in-page.component.scss']
})

export class SignInPageComponent implements AfterViewInit, OnDestroy, OnInit {

    apiUrl: string;
    data: any = {};
    errorMessage: string;
    hidePassword = true;
    signInForm: FormGroup;
    signInProcessInProgress: boolean;

    // password: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private formBuilder: FormBuilder,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService) {
        this.apiUrl = this.configService.apiUrl;
        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    onClose(): void {
        this.errorMessage = null;
        this.signInProcessInProgress = false;
    }

    oauth(provider: string): void {
        window.open(this.apiUrl + '/oauth/' + provider, '_self');
    }

    goTo(state: string): void {
        this.router.navigate([state]);
    }

    onSubmitSignInForm(): void {
        if (this.signInForm.valid) {
            const data: any = {};
            data.email = this.signInForm.controls.email.value;
            data.password = this.signInForm.controls.password.value;
            this.authService.signIn(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: any): void => {
                    console.log(res.message);
                    if (res.status === 'ok') {
                        this.router.navigate(['dashboard']);
                    } else {
                        this.errorMessage = res.message;
                    }
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                    console.log(err);
                });
            this.signInProcessInProgress = true;
        }
        this.errorMessage = null;
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.initSignUpForm();

        this.signInForm.controls.email.setValue('sukarimo@indiana.edu');
        this.signInForm.controls.password.setValue('12345678');
        this.signInForm.markAsDirty();
    }

    private initSignUpForm(): void {
        this.signInForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]]
        });
    }
}
