import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';

@Injectable()
export class DomService {

    constructor(private appRef: ApplicationRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private injector: Injector) {
    }

    appendComponent(host: HTMLElement, component: any, scope?: Object): void {
        // 1. Create a component reference from the component.
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        for (const key in scope) {
            if (scope.hasOwnProperty(key)) {
                componentRef.instance[key] = scope[key];
            }
        }

        // 2. Attach component to the appRef so that it's inside the ng component tree.
        this.appRef.attachView(componentRef.hostView);

        // 3. Get DOM element from component.
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the host.
        host.appendChild(domElem);

        // 5. Wait some time and remove it from the component tree and from the DOM.
        // setTimeout((): void => {
        //     this.appRef.detachView(componentRef.hostView);
        //     componentRef.destroy();
        // }, 3000);
    }
}
