import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../modules/config/config.service';
import {Subject} from 'rxjs';
import {TestAnswer} from '../../interfaces/test.interface';

@Component({
    selector: 'app-test-answer',
    templateUrl: 'test-answer.component.html',
    styleUrls: ['test-answer.component.scss']
})
export class TestAnswerComponent implements OnInit {

    @Input() data: TestAnswer;
    @Input() i: number;

    isSubPage: boolean;
    /*answer: any = {
        type: 'image',
        file: '/image/test.jpeg',
        text: 'sliue57f3o48756f3',
        isCorrect: false
    };*/

    private ngUnsubscribe = new Subject<void>();

    constructor(private configService: ConfigService) {
    }

    ngOnInit(): void {
    }
}
