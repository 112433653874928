import {AfterViewInit, Component, OnInit} from '@angular/core';

import {Test} from '../../shared/interfaces/test.interface';
import {TestPlayerService} from '../../services/test-player.service';
import {ConfigService} from '../../modules/config/config.service';
import {MONGOLIAN_AUDIO_MOCK_TEST} from '../../shared/utils/mongolian-audio-test.mock';
import {MONGOLIAN_READING_MOCK_TEST} from '../../shared/utils/mongolian-reading-test.mock';
import {MONGOLIAN_GRAMMAR_MOCK_TEST} from '../../shared/utils/mongolian-grammar-test.mock';

@Component({
    selector: 'test2',
    templateUrl: 'test2-page.component.html',
    styleUrls: ['test2-page.component.scss']
})
export class Test2PageComponent implements AfterViewInit, OnInit {

    testData: Test;

    constructor(private configService: ConfigService,
                private testPlayerService: TestPlayerService) {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.testData = MONGOLIAN_GRAMMAR_MOCK_TEST;
    }
}
