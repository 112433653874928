import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';

import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../modules/config/config.service';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {TtUser} from '../../shared/interfaces/user.interface';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'ui-header',
    templateUrl: 'ui-header.component.html',
    styleUrls: ['ui-header.component.scss']
})
export class UIHeaderComponent implements OnInit {

    currentUser: any;

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private userService: UserService) {
    }

    isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    signOut(): void {
        this.authService.signOut();
    }

    ngOnInit(): void {
        this.userService.currentUser$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: any | null): void => {
                this.currentUser = user;
            });
    }
}
