import {catchError, map} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs/index';

import {ConfigService} from '../modules/config/config.service';
import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {Test, TestResults, TestStatus} from '../shared/interfaces/test.interface';
import {CommonUtil} from '../shared/utils/common.util';

@Injectable()
export class TestPlayerService {

    currentTest: Test;
    currentTestResults$ = new ReplaySubject(1);
    currentTestStatus$ = new ReplaySubject(1);

    private _currentTestResults: TestResults = {};
    private _currentTestStatus: TestStatus = {};
    private apiUrl: string;

    constructor(private configService: ConfigService,
                private http: HttpClient) {
        this.apiUrl = this.configService.apiUrl;
    }

    set currentTestResults(testResults: TestResults) {
        let paramsIsModified = false;
        for (const param in testResults) {
            if (testResults.hasOwnProperty(param)) {
                if (this._currentTestResults[param] !== testResults[param]) {
                    this._currentTestResults[param] = testResults[param];
                    paramsIsModified = true;
                }
            }
        }
        if (paramsIsModified) {
            this.currentTestResults$.next(CommonUtil.cloneObject(this._currentTestResults));
        }
    }

    get currentTestResults(): TestResults {
        return CommonUtil.cloneObject(this._currentTestResults);
    }

    set currentTestStatus(testStatus: TestStatus) {
        let paramsIsModified = false;
        for (const param in testStatus) {
            if (testStatus.hasOwnProperty(param)) {
                if (this._currentTestStatus[param] !== testStatus[param]) {
                    this._currentTestStatus[param] = testStatus[param];
                    paramsIsModified = true;
                }
            }
        }
        if (paramsIsModified) {
            this.currentTestStatus$.next(CommonUtil.cloneObject(this._currentTestStatus));
        }
    }

    get currentTestStatus(): TestStatus {
        return CommonUtil.cloneObject(this._currentTestStatus);
    }

    startTest(test: Test, user: string): void {
        this.currentTest = test;

        this.currentTestResults = {
            hash: (test.name + user).replace(' ', '').toLocaleLowerCase(),
            name: test.name,
            user,
            startTime: new Date(),
            endTime: null,
            lastSetIndex: undefined,
            setResults: []
        };

        this.currentTestStatus = {
            name: test.name,
            startTime: new Date(),
            currentGroupIndex: undefined,
            currentGroupStartTime: null,
            currentSetDuration: null,
            currentSetIndex: undefined,
            currentSetName: undefined,
            currentSetStartTime: null
        };
    }

    nextSet(): void {
        const currentTestStatus = this.currentTestStatus;
        if (currentTestStatus && currentTestStatus.currentSetIndex >= 0) {
            console.log('a');
            if ((this.currentTest.sets.length - 1) > currentTestStatus.currentSetIndex) {
                console.log('b');
                currentTestStatus.currentSetIndex += 1;
                currentTestStatus.currentSetName = this.currentTest.sets[currentTestStatus.currentSetIndex].name;
                currentTestStatus.currentSetStartTime = new Date();
                currentTestStatus.currentSetDuration = this.currentTest.sets[currentTestStatus.currentSetIndex].duration;
                this.currentTestResults = {lastSetIndex: currentTestStatus.currentSetIndex};
                this.currentTestStatus = currentTestStatus;
            } else {
                /// If no more sets...
                console.log('If no more sets...');
            }
        } else {
            currentTestStatus.currentSetIndex = 0;
            currentTestStatus.currentSetName = this.currentTest.sets[0].name;
            currentTestStatus.currentSetStartTime = new Date();
            currentTestStatus.currentSetDuration = this.currentTest.sets[0].duration;
            this.currentTestStatus = currentTestStatus;
        }
    }

    nextGroup(): void {
        const currentTestStatus = this.currentTestStatus;
        if (currentTestStatus && currentTestStatus.currentGroupIndex >= 0) {
            if ((this.currentTest.sets[currentTestStatus.currentSetIndex].groups.length - 1) > currentTestStatus.currentGroupIndex) {
                currentTestStatus.currentGroupIndex++;
                currentTestStatus.currentGroupStartTime = new Date();
                this.currentTestStatus = currentTestStatus;
            } else {
                currentTestStatus.currentGroupIndex = undefined;
                currentTestStatus.currentGroupStartTime = null;
                this.currentTestStatus = currentTestStatus;
                this.nextSet();
            }
        } else {
            currentTestStatus.currentGroupIndex = 0;
            currentTestStatus.currentGroupStartTime = new Date();
            this.currentTestStatus = currentTestStatus;
        }
    }
}
