import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, map, mergeMap, takeUntil} from 'rxjs/internal/operators';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/index';
import {Title} from '@angular/platform-browser';

import {AuthService} from './services/auth.service';
import {ConfigService} from './modules/config/config.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

    private ngUnsubscribe = new Subject<void>();
    private titleProp: string;
    private translations: any;

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private title: Title,
                private translateService: TranslateService) {
    }

    getTranslations(): void {
        this.translateService.get(['pageTitles'])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((translations: any): void => {
                this.translations = translations.pageTitles;
                this.setTitle(this.translations, this.titleProp);
            }, (err: Error): void => {
                console.log(err);
            });
    }

    setTitle(translations: any, titlePop: string): void {
        if (translations && typeof translations === 'object') {
            if (titlePop) {
                this.title.setTitle(translations[titlePop] + ' | ' + translations['celcarTest']);
            } else {
                this.title.setTitle(translations['celcarTest']);
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.getTranslations();

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.route))
            .pipe(map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }))
            .pipe(filter(route => route.outlet === 'primary'))
            .pipe(mergeMap(route => route.data))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any): void => {
                if (data) {
                    this.titleProp = data.title;
                }
                this.setTitle(this.translations, this.titleProp);
                this.configService.mainRouterOutletData = data;
            });

        this.translateService.onLangChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: LangChangeEvent): void => {
                this.getTranslations();
            });
    }
}
