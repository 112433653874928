import {forkJoin, Observable, of} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {ConfigService} from '../modules/config/config.service';
import {TtBase64ImageData} from '../shared/interfaces/file-data.interface';

@Injectable()
export class FileService {

    private apiUrl: string;

    constructor(private configService: ConfigService,
                private http: HttpClient) {
        this.apiUrl = this.configService.apiUrl + '/files';
    }

    uploadBase64Image(imageData: TtBase64ImageData | TtBase64ImageData[]): Observable<Array<any>> {
        const observables: Observable<object>[] = [];
        if (!Array.isArray(imageData)) {
            imageData = [imageData];
        }
        for (let i = 0; i < imageData.length; i++) {
            observables.push(this.http.post(this.apiUrl + '/base64-image', imageData[i]));
        }
        return forkJoin(observables);
    }
}
