import {catchError, map} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs/index';
import {Router} from '@angular/router';

import {ConfigService} from '../modules/config/config.service';
import {StorageService} from './storage.service';
import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {TtUser} from '../shared/interfaces/user.interface';
import {UserService} from './user.service';
import {ReplaySubject} from 'rxjs';

@Injectable()
export class AuthService {

    private apiUrl: string;

    constructor(private configService: ConfigService,
                private http: HttpClient,
                private router: Router,
                private storageService: StorageService,
                private userService: UserService) {
        this.apiUrl = this.configService.apiUrl;
    }

    forgotPassword(forgotPasswordData: { email: string }): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/forgot-password', forgotPasswordData)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    isLoggedIn(): boolean {
        return !!this.storageService.getItem('token');
    }


    // To be developed on a server side
    resetPassword(resetPasswordData: { textId: string, recoveryCode: string, newPassword: string }): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/reset_password.php', resetPasswordData)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    signIn(signInData: TtUser): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/login.php', signInData)
            .pipe(
                map((res: { status: string; message: string; user: TtUser, token: any }): any => {
                    console.log(res);
                    this.storageService.setItem('token', res.token);
                    this.userService.currentUser = res.user;
                    console.log(this.userService.currentUser);
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    // console.log(err);
                    return throwError(err);
                })
            );
    }

    checkForEmail(emailData: TtUser): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/check-for-email.php', emailData)
            .pipe(
                /*SRK: Check logic as we need only to confirm that email exists and automated password recovery link is sent to it*/
                map((res: { status: string; message: string; user: TtUser, token: any }): any => {
                    console.log(res);
                    this.storageService.setItem('token', res.token);
                    this.userService.currentUser = res.user;
                    console.log(this.userService.currentUser);
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    // console.log(err);
                    return throwError(err);
                })
            );
    }

    signOut(): void {
        this.storageService.removeItem('token');
        this.userService.currentUser = null;
        this.router.navigate(['/sign-in']);
    }

    signUp(userData: TtUser): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/create_user.php', userData)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }
}
