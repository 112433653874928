import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../modules/config/config.service';
import {TtErrorResponse} from '../../shared/interfaces/error-response.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: 'forgot-password-page.component.html',
    styleUrls: ['forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent implements OnDestroy, OnInit {

    apiUrl: string;
    data: any = {};
    errorMessage: string;
    successMessage: string;
    forgotPasswordForm: FormGroup;
    forgotPasswordFormProcessInProgress: boolean;

    private ngUnsubscribe = new Subject<void>();

    constructor(private authService: AuthService,
                private formBuilder: FormBuilder,
                private configService: ConfigService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService) {
        this.apiUrl = this.configService.apiUrl;
        this.translateService.onLangChange
            .subscribe((data): void => {
                console.log('onLangChange data', data);
            }, (err): void => {
                console.log('onLangChange err', err);
            }, (complete): void => {
                console.log('onLangChange complete', complete);
            });
    }

    onClose(): void {
        this.errorMessage = null;
        this.forgotPasswordFormProcessInProgress = false;
    }

    goTo(state: string): void {
        this.router.navigate([state]);
    }

    onSubmitCheckForEmailForm(): void {
        if (this.forgotPasswordForm.valid) {
            const data: any = {};
            data.email = this.forgotPasswordForm.controls.email.value;
            this.authService.checkForEmail(data)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: any): void => {
                    console.log(res.message);
                    if (res.status === 'ok') {
                        //in this case we should show Message "Check your email"
                        this.successMessage = res.message;
                    } else {
                        //show Error message
                        this.errorMessage = res.message;
                    }
                }, (err: TtErrorResponse): void => {
                    this.configService.errorHandler(err);
                    console.log(err);
                });
            this.forgotPasswordFormProcessInProgress = true;
        }
        this.successMessage = null;
        this.errorMessage = null;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.initForgotPasswordForm();
    }

    private initForgotPasswordForm(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }
}
