import {Injectable} from '@angular/core';

import {TtCookieOptions} from '../shared/interfaces/cookie-options.interface';

@Injectable()
export class StorageService {

    private data: any = {};
    private hasLocalStorage = true;

    constructor() {
        if (typeof localStorage === 'object') {
            try {
                const str = '$LocalStorageTest';
                localStorage.setItem(str, str);
                localStorage.removeItem(str);
            } catch (e) {
                this.hasLocalStorage = false;
            }
        } else {
            this.hasLocalStorage = false;
        }
    }

    getItem(key: string): string | null {
        let item: string;
        if (this.hasLocalStorage) {
            item = localStorage.getItem(key);
        } else {
            item = this.data[name];
        }

        try {
            const parsedItem = JSON.parse(item);
            if (parsedItem.options && parsedItem.options.expires) {
                const now = new Date().getTime();
                if (now < parsedItem.options.expires) {
                    return JSON.stringify(parsedItem.value);
                } else {
                    localStorage.removeItem(key);
                    return null;
                }
            } else {
                return item;
            }
        } catch (e) {
            return item;
        }
    }

    setItem(key: string, value: string, options?: TtCookieOptions): void {
        if (options) {
            if (options.expires) { // expires - value in hours
                options.expires = new Date().getTime() + (options.expires * 3600000);
            }
            const data = {
                value: value,
                options: options
            };
            value = JSON.stringify(data);
        }

        if (this.hasLocalStorage) {
            localStorage.setItem(key, value);
        } else {
            this.data[key] = value;
        }
    }

    removeItem(key: string): void {
        if (this.hasLocalStorage) {
            localStorage.removeItem(key);
        } else {
            this.data[name] = null;
        }
    }
}
