import {catchError, map} from 'rxjs/internal/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs/index';

import {ConfigService} from '../modules/config/config.service';
import {TtErrorResponse} from '../shared/interfaces/error-response.interface';
import {TtForm} from '../shared/interfaces/questionnaire.interface';

@Injectable()
export class SetService {

    currentSet$ = new ReplaySubject(1);

    private _currentSet: any;
    private apiUrl: string;

    constructor(private configService: ConfigService,
                private http: HttpClient) {
        this.apiUrl = this.configService.apiUrl;
    }

    set currentTest(test: any) {
        this._currentSet = test;
        this._currentSet.next(this._currentSet);
    }

    get currentTest(): any {
        return this._currentSet;
    }

    retrieveById(id: string): Observable<any | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/read_one.php?id=' + id)
            .pipe(
                map((res: any): any => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    search(params?: string): Observable<TtForm[] | TtErrorResponse> {
        return this.http.get(this.apiUrl + '/search' + (params ? '?' + params : ''))
            .pipe(
                map((res: TtForm[]): TtForm[] => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    update(user: TtForm): Observable<TtForm | TtErrorResponse> {
        return this.http.put(this.apiUrl + '/update_set.php', user)
            .pipe(
                map((res: TtForm): TtForm => {
                    return res;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    delete(): Observable<TtErrorResponse> {
        return this.http.delete(this.apiUrl)
            .pipe(
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }

    // refactor any type to TtTest
    addSet(setData: any): Observable<string | TtErrorResponse> {
        return this.http.post(this.apiUrl + '/create_set.php', setData)
            .pipe(
                map((successMessage: string): string => {
                    return successMessage;
                }),
                catchError((err: TtErrorResponse): Observable<TtErrorResponse> => {
                    return throwError(err);
                })
            );
    }


}
